// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogModal_LogList__ZmGsI {
  margin: 0;
  padding: 0;
  list-style: none;
}
.LogModal_LogList__ZmGsI li {
  padding: 8px 10px;
}
.LogModal_LogList__ZmGsI li p {
  margin-bottom: 0;
}
.LogModal_LogList__ZmGsI li:nth-child(odd) {
  border: 1px solid #ccc;
  border-left: none;
  border-right: none;
  background-color: #f1f1f1;
}`, "",{"version":3,"sources":["webpack://./src/Components/LogModal/index.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,UAAA;EACA,gBAAA;AACF;AAAE;EACE,iBAAA;AAEJ;AADI;EACI,gBAAA;AAGR;AADI;EACI,sBAAA;EACA,iBAAA;EACA,kBAAA;EACF,yBAAA;AAGN","sourcesContent":[".LogList {\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  li {\n    padding: 8px 10px;\n    p{\n        margin-bottom: 0;\n    }\n    &:nth-child(odd) {\n        border: 1px solid #ccc;\n        border-left: none;\n        border-right: none;\n      background-color: #f1f1f1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LogList": `LogModal_LogList__ZmGsI`
};
export default ___CSS_LOADER_EXPORT___;
