import React, { useEffect, useMemo, useState } from "react";
import Heading from "Components/Heading"; // Assuming Heading is a React component
import Footer from "Components/Footer"; // Assuming Footer is a React component
import { Card, Col, Form, Row } from "react-bootstrap";
import classes from "./index.module.scss";
import img1 from "../../Images/dash1.svg";
import img2 from "../../Images/dash2.svg";
import img3 from "../../Images/dash3.svg";
import img4 from "../../Images/dash4.svg";
import { Bar, Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js"; // Import CategoryScale
import { Chart, registerables } from "chart.js";
import SampleDataTable from "Components/DataTables";
import TicketAPIs from "../../APIs/ticket";
import Loader from "Components/Loader";
import { BiLike } from "react-icons/bi";
import { formatDate } from "Helper/Converters";
import { useSelector } from "react-redux";
import Select from "react-select";

const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "44px",
  }),
};

const columns = [
  {
    accessor: "ticketNo",
    Header: "Ticket No",
    Cell: ({ row }) => (
      <a
        onClick={(e) => {
          e.preventDefault();
          const url = `/dashboard/ticket-detail/${row.original.ticketNo}`;
          // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
          const windowName = `popUpWindow_${row.original.ticketNo}`;
          const windowSize = "width=800,height=600";
          window.open(url, windowName, windowSize);
        }}
        href={`/dashboard/ticket-detail/${row.original.ticketNo}`}
        rel="noopener noreferrer"
        className={`badge ${
          row.original.ticketType == "EMERGENCY" ||
              row.original.priority == "EMERGENCY"
                ? 'emergency'
                : row.original.ticketType == "MULTIPLE" ||
                  row.original.priority == "MULTIPLE"
                ? 'multiple'
                : row.original.ticketType == "PRIORITY" ||
                  row.original.priority == "PRIORITY"
                ? 'priority'
                : row.original.ticketType == "PROJECT WORK" ||
                  row.original.priority == "PROJECT WORK"
                ? 'projectWork'
                : row.original.ticketType == "STANDARD" ||
                  row.original.priority == "STANDARD"
                ? 'standard'
                : 'default'
        }`}
      >
        {row.original.ticketNo}
      </a>
    ),
  },
  { accessor: "address", Header: "Location" },
  {
    Header: "Entered Date",
    Cell: ({ row }) => (
      <p>
        {row.original.enteredDate ? formatDate(row.original.enteredDate) : ""}
      </p>
    ),
  },
  {
    Header: "Assigned Date",
    Cell: ({ row }) => (
      <p>
        {row.original.assignDate ? formatDate(row.original.assignDate) : ""}
      </p>
    ),
  },
  {
    Header: "Reassigned Date",
    Cell: ({ row }) => (
      <p>
        {row.original.reAssignDate ? formatDate(row.original.reAssignDate) : ""}
      </p>
    ),
  },
  {
    Header: "Station Code",
    Cell: ({ row }) => (
      <p>{row.original.stationCodes?.map((item) => item.name).join(", ")}</p>
    ),
  },
];

const stationCodeCompletionColumns = [
  {
    Header: "Station Code",
    Cell: ({ row }) => <p>{row.original.stationCode}</p>,
  },
  {
    Header: "Completed",
    Cell: ({ row }) => <p>{row.original.completedTickets}</p>,
  },
  {
    Header: "Incomplete",
    Cell: ({ row }) => <p>{row.original.incompleteTickets}</p>,
  },
];

const userStatsColumns = [
  {
    Header: "Name",
    Cell: ({ row }) => (
      <p>{row.original.firstname ? row.original.firstname : "None"}</p>
    ),
  },
  {
    Header: "Completed",
    Cell: ({ row }) => <p>{row.original.completedtickets}</p>,
  },
  {
    Header: "Incomplete",
    Cell: ({ row }) => <p>{row.original.incompletetickets}</p>,
  },
  {
    Header: "Late",
    Cell: ({ row }) => <p>{row.original.rescheduledaftercompletion}</p>,
  },
  {
    Header: "Emergency",
    Cell: ({ row }) => <p>{row.original.emergencytickets}</p>,
  },
];

const options = {
  // ... other options
  indexAxis: "y",
  plugins: {
    legend: {
      display: true, // Show the legend
      position: "bottom", // Position of the legend (for bar chart)
      labels: {
        usePointStyle: true, // Use dataset's point style for legend
      },
      onClick: (e) => {
        // Handle legend item click events here, if needed
      },
      horizontal: true,
    },
  },
};

const options1 = {
  // ... other options
  indexAxis: "x",
  plugins: {
    legend: {
      display: true, // Show the legend
      position: "bottom", // Position of the legend (for bar chart)
      labels: {
        usePointStyle: true, // Use dataset's point style for legend
      },
      onClick: (e) => {
        // Handle legend item click events here, if needed
      },
    },
  },
};

const data2 = {
  // Data for Line Chart with two lines
  labels: [
    "Jan",
    "Fab",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Completed Tickets", // Line 1
      data: [30, 20, 40, 25, 35, 30, 45, 32, 40, 28, 30],
      type: "line",
      borderColor: "#007EF2", // Blue
      borderWidth: 2,
      fill: false,
      tension: 0.5, // Adjust the tension to make the line wavy
      yAxisID: "y1", // Assign a unique yAxisID to each line dataset
    },
    {
      label: "Total Users", // Line 2 (Add a new dataset for the second line)
      data: [5, 15, 25, 0, 30, 12, 0, 0, 20, 35, 18],
      type: "line",
      borderColor: "#C73311", // Red
      borderWidth: 2,
      fill: false,
      tension: 0.5, // Adjust the tension to make the line wavy
      yAxisID: "y2", // Assign a unique yAxisID to each line dataset
    },
  ],
};

const options2 = {
  scales: {
    yAxes: [
      {
        id: "y1",
        position: "left",
        ticks: {
          display: false, // Hide y-axis numbers
        },
        gridLines: {
          display: false, // Hide background grid lines
        },
      },
      {
        id: "y2",
        position: "right",
        ticks: {
          display: false, // Hide y-axis numbers
        },
        gridLines: {
          display: false, // Hide background grid lines
        },
      },
    ],
  },
  plugins: {
    legend: {
      display: true,
      position: "top",
      labels: {
        usePointStyle: true,
      },
      onClick: (e) => {
        // Handle legend item click events here, if needed
      },
    },
  },
};

Chart.register(...registerables);

const DROPDOWN_DATA = [
  { label: 'TODAY', value: 'TODAY' },
  { label: 'WEEK', value: 'WEEK' },
  { label: 'MONTH', value: 'MONTH' },
  { label: 'YEAR', value: 'YEAR' },
]

const AdminDashboard = () => {
  const { department } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(DROPDOWN_DATA[0]);

  const getData = async () => {
    setLoading(true);
    const res = await TicketAPIs.adminDashboardStats(selectedOption.value);
    if (res?.data?.success) {
      const userStats =
        res.data.data?.userStats?.filter((item) => item.firstname) || [];
      setData({ ...res.data.data, userStats });
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [selectedOption]);

  const stationCodeStatsData = useMemo(() => {
    const stationCodes =
      data?.stationCodes?.map((item) => item.stationCode) || [];
    const completedTickets =
      data?.stationCodes?.map((item) => Number(item.completedTickets)) || [];
    const incompleteTickets =
      data?.stationCodes?.map((item) => Number(item.incompleteTickets)) || [];
    return {
      // Separate datasets for bars and line
      labels: stationCodes,
      datasets: [
        {
          // Bar Dataset 1
          label: "Completed Tickets",
          data: completedTickets,
          backgroundColor: "blue",
          borderColor: "blue",
          borderWidth: 0,
          metadata: {
            customLabel: "Assigned Tickets Count", // Optional custom label for legend
          },
        },
        {
          // Bar Dataset 2
          label: "Incomplete Tickets",
          data: incompleteTickets,
          backgroundColor: "orange",
          borderColor: "orange",
          borderWidth: 0,
          metadata: {
            customLabel: "Reassigned Tickets Count", // Optional custom label for legend
          },
        },
        // {
        //   // Bar Dataset 2
        //   label: "Total Users",
        //   data: [5, 15, 25, 0, 30, 12, 0, 0, 20, 35, 18],
        //   backgroundColor: "#0073BA",
        //   borderColor: "#0073BA",
        //   borderWidth: 0,
        //   metadata: {
        //     customLabel: "Total Users", // Optional custom label for legend
        //   },
        // },
        // {
        //   // Line Dataset (Not displayed in bar chart)
        //   label: "Line",
        //   data: [10, 15, 30, 20, 30, 25, 25, 22, 35, 18, 18],
        //   type: "line",
        //   fill: false,
        //   borderColor: "#B9B9B9",
        //   borderWidth: 2,
        //   legend: {
        //     display: false,
        //   },
        // },
      ],
    };
  }, [data]);

  const userStatsData = useMemo(() => {
    const userLabel = data?.userStats?.map((item) => item.firstname || "None");

    const completedTickets =
      data?.userStats?.map((item) => Number(item.completedtickets)) || [];
    const incompleteTickets =
      data?.userStats?.map((item) => Number(item.incompletetickets)) || [];
    const lateTickets =
      data?.userStats?.map((item) => Number(item.rescheduledaftercompletion)) ||
      [];
    const emergencyTickets =
      data?.userStats?.map((item) => Number(item.emergencytickets)) || [];
    return {
      // Separate datasets for bars and line
      labels: userLabel,
      datasets: [
        {
          // Bar Dataset 1
          label: "Completed Tickets",
          data: completedTickets,
          backgroundColor: "blue",
          borderColor: "blue",
          borderWidth: 0,
          metadata: {
            customLabel: "Assigned Tickets Count", // Optional custom label for legend
          },
        },
        {
          // Bar Dataset 2
          label: "Incomplete Tickets",
          data: incompleteTickets,
          backgroundColor: "orange",
          borderColor: "orange",
          borderWidth: 0,
          metadata: {
            customLabel: "Incomplete Tickets Count", // Optional custom label for legend
          },
        },
        {
          // Bar Dataset 2
          label: "Late Tickets",
          data: lateTickets,
          backgroundColor: "red",
          borderColor: "red",
          borderWidth: 0,
          metadata: {
            customLabel: "Late Tickets Count", // Optional custom label for legend
          },
        },
        {
          // Bar Dataset 2
          label: "Emergency Tickets",
          data: emergencyTickets,
          backgroundColor: "yellow",
          borderColor: "yellow",
          borderWidth: 0,
          metadata: {
            customLabel: "Emergency Tickets Count", // Optional custom label for legend
          },
        },
      ],
    };
  }, [data]);

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <section>
          <div className="d-flex justify-content-between">
            <Heading text={"Admin Dashboard"} />
            <Form.Group className="form-group">
              <Select
                options={DROPDOWN_DATA}
                styles={customStyles}
                value={selectedOption}
                onChange={(selectedOption) => {
                  setSelectedOption(selectedOption);
                }}
              />
            </Form.Group>
          </div>
          <Row className=" align-items-stretch">
            <Col xl={3} sm={6} className="mb-3">
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <img src={img2} alt="img" />
                </div>
                <div>
                  <h3 className="mb-3">{data?.totalTickets}</h3>
                  <h6 className="text-muted">Total Tickets</h6>
                </div>
              </Card>
            </Col>
            <Col xl={3} sm={6} className="mb-3">
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <img src={img2} alt="img" />
                </div>
                <div>
                  <h3 className="mb-3">{data?.completedTickets}</h3>
                  <h6 className="text-muted">Completed Tickets</h6>
                </div>
              </Card>
            </Col>
            <Col xl={3} sm={6} className="mb-3">
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <img src={img3} alt="img" />
                </div>
                <div>
                  <h3 className="mb-3">{data?.assignedOrReassignedTickets}</h3>
                  <h6 className="text-muted">Assigned Tickets</h6>
                </div>
              </Card>
            </Col>
          </Row>
          {department?.name == 'locator' &&
            <Row className="mb-3 align-items-stretch">
              <Col lg={6}>
                <Card className="h-100">
                  <h6>Station Codes</h6>
                  <SampleDataTable
                    columns={stationCodeCompletionColumns}
                    data={data?.stationCodes || []}
                  />
                </Card>
              </Col>
              <Col lg={6}>
                <Card className="h-100">
                  <h6>Statistics</h6>
                  <Bar data={stationCodeStatsData} options={options} />
                </Card>
              </Col>
            </Row>
          }
          {(department?.name == 'admin' || department?.name == 'Tester') &&
            <Row className="mb-3 align-items-stretch">
              <Col lg={6}>
                <Card className="h-100">
                  <h6>User Tickets Stats</h6>
                  <SampleDataTable
                    columns={userStatsColumns}
                    data={data?.userStats || []}
                  />
                </Card>
              </Col>
              <Col lg={6}>
                <Card className="h-100">
                  <h6>User Statistics</h6>
                  <Bar data={userStatsData} options={options2} />
                </Card>
              </Col>
            </Row>
          }
          {department?.name == 'locator' &&
            <Card>
              <Card>
                <h6>Latest Tickets</h6>
                <SampleDataTable
                  columns={columns}
                  data={data?.latestTickets || []}
                />
              </Card>
              <Footer />
            </Card>
          }
        </section>
      )}
    </>
  );
};

export default AdminDashboard;
