import Heading from "Components/Heading";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Footer from "Components/Footer";
import Loader from "Components/Loader";
import TicketAPIs from "../../APIs/ticket";
import SampleDataTable from "Components/DataTables";
import UserTicketDetail from "./components/UserTicketDetails";

const OutstandingTickets = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchedData, setSearchedData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState(null);
  const [selectedTab, setSelectedTab] = useState('tab1');

  const getTicketOutstanding = async () => {
    setIsLoading(true);
    const res = await TicketAPIs.ticketOutstanding();
    if (res) {
      setSearchedData(res.data.data);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUsers(user);
    getTicketOutstanding();
  }, []);

  const columns = [
    {
      accessor: "username",
      Header: "User",
      Cell: ({ row }) => (
        <p>
          <a
            href="#!"
            onClick={(e) => {
              setSelectedUserID(row.original.userId);
              e.preventDefault();
              setModalShow(true);
            }}
            rel="noopener noreferrer"
            style={{ marginRight: 10 }}
          >
            {row.original.username} {/* todo: we have to change it to firstName from backend and frontend */}
          </a>
          {"("}
          <a
            href="#"
            onClick={(e) => {
              setSelectedUserID(row.original.userId);
              setSelectedTab('tab1')
              e.preventDefault();
              setModalShow(true);
            }}
            rel="noopener noreferrer"
          >
            show map
          </a>
          {") ("}
          <a
            href="#"
            onClick={(e) => {
              setSelectedUserID(row.original.userId);
              setSelectedTab('tab2')
              e.preventDefault();
              setModalShow(true);
            }}
            rel="noopener noreferrer"
          >
            show ticket
          </a>
          {")"}
        </p>
      ),
    },
    { accessor: "assignedCount", Header: "Assigned" },
    { accessor: "preCompletedCount", Header: "Pre-Completed" },
  ];


  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <section>
          <Heading text={"Outstanding Tickets"} />

          <Card>
            <SampleDataTable columns={columns} data={searchedData} />
            <UserTicketDetail
              show={modalShow}
              onHide={() => setModalShow(false)}
              userId={selectedUserID}
              selectedTab={selectedTab}
            />
            <Footer />
          </Card>
        </section>
      )}
    </>
  );
};

export default OutstandingTickets;
