import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { TbClipboardCheck } from "react-icons/tb";
import Heading from "Components/Heading";
import Footer from "Components/Footer";
import DataTable from "Components/CustomTable";
import AuthAPIs from "../../APIs/auth";
import { useSelector } from "react-redux";
import TicketAPIs from "../../APIs/ticket";
import { toast } from "react-toastify";
import { formatDate } from "Helper/Converters";

const CloseOutstanding = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useSelector((state) => state.auth);
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchedData, setSearchedData] = useState([]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px", // Set the minimum height as needed
    }),
  };

  const options = [
    { value: "0", label: "Open this select menu" },
    { value: "1", label: "One" },
    { value: "2", label: "Two" },
    { value: "3", label: "Three" },
  ];

  const dummyData = [
    {
      id: 1,
      ticket: "20234316270",
      location:
        "MONUMENT TRAIL(York Rural Region) CABLE CONTROLS SYSTEMS BRIANNA KUJANEN BRAMPTON( routine )",
      entered: "OCT 18, 2023",
      assign: "OCT 31, 2023",
      assigntoo: "Harry Singh",
      reassigned: "Oct 31, 2023",
      due: "Nov 01, 2023",
      code: "CBGW01 PBTCU02 PBTCU01",
      oc: true,
    },
    {
      id: 1,
      ticket: "20234316270",
      location:
        "MONUMENT TRAIL(York Rural Region) CABLE CONTROLS SYSTEMS BRIANNA KUJANEN BRAMPTON( routine )",
      entered: "OCT 18, 2023",
      assign: "OCT 31, 2023",
      assigntoo: "",
      reassigned: "Oct 31, 2023",
      due: "Nov 01, 2023",
      code: "CBGW01 PBTCU02 PBTCU01",
      oc: false,
    },
    {
      id: 1,
      ticket: "20234316270",
      location:
        "MONUMENT TRAIL(York Rural Region) CABLE CONTROLS SYSTEMS BRIANNA KUJANEN BRAMPTON( routine )",
      entered: "OCT 18, 2023",
      assign: "OCT 31, 2023",
      assigntoo: "",
      reassigned: "Oct 31, 2023",
      due: "Nov 01, 2023",
      code: "CBGW01 PBTCU02 PBTCU01",
      oc: false,
    },
    {
      id: 1,
      ticket: "20234316270",
      location:
        "MONUMENT TRAIL(York Rural Region) CABLE CONTROLS SYSTEMS BRIANNA KUJANEN BRAMPTON( routine )",
      entered: "OCT 18, 2023",
      assign: "OCT 31, 2023",
      assigntoo: "",
      reassigned: "Oct 31, 2023",
      due: "Nov 01, 2023",
      code: "CBGW01 PBTCU02 PBTCU01",
      oc: false,
    },
    {
      id: 1,
      ticket: "20234316270",
      location:
        "MONUMENT TRAIL(York Rural Region) CABLE CONTROLS SYSTEMS BRIANNA KUJANEN BRAMPTON( routine )",
      entered: "OCT 18, 2023",
      assign: "OCT 31, 2023",
      assigntoo: "",
      reassigned: "Oct 31, 2023",
      due: "Nov 01, 2023",
      code: "CBGW01 PBTCU02 PBTCU01",
      oc: false,
    },
    {
      id: 1,
      ticket: "20234316270",
      location:
        "MONUMENT TRAIL(York Rural Region) CABLE CONTROLS SYSTEMS BRIANNA KUJANEN BRAMPTON( routine )",
      entered: "OCT 18, 2023",
      assign: "OCT 31, 2023",
      assigntoo: "",
      reassigned: "Oct 31, 2023",
      due: "Nov 01, 2023",
      code: "CBGW01 PBTCU02 PBTCU01",
      oc: false,
    },
    {
      id: 1,
      ticket: "20234316270",
      location:
        "MONUMENT TRAIL(York Rural Region) CABLE CONTROLS SYSTEMS BRIANNA KUJANEN BRAMPTON( routine )",
      entered: "OCT 18, 2023",
      assign: "OCT 31, 2023",
      assigntoo: "",
      reassigned: "Oct 31, 2023",
      due: "Nov 01, 2023",
      code: "CBGW01 PBTCU02 PBTCU01",
      oc: false,
    },
    {
      id: 1,
      ticket: "20234316270",
      location:
        "MONUMENT TRAIL(York Rural Region) CABLE CONTROLS SYSTEMS BRIANNA KUJANEN BRAMPTON( routine )",
      entered: "OCT 18, 2023",
      assign: "OCT 31, 2023",
      assigntoo: "",
      reassigned: "Oct 31, 2023",
      due: "Nov 01, 2023",
      code: "CBGW01 PBTCU02 PBTCU01",
      oc: false,
    },
  ];

  const columns = [
    { id: "ticket", label: "Ticket No" },
    { id: "location", label: "Location" },
    { id: "entered", label: "Entered" },
    { id: "due", label: "Due" },
    { id: "assign", label: "Assigned Date" },
    { id: "assigntoo", label: "Assigned To" },
    { id: "reassigned", label: "Pre Completed Date" },
    { id: "code", label: "Station Code" },
    { id: "oc", label: "OC" },
  ];

  /**
   * Get All Users.
   */
  const getUsers = async () => {
    setIsLoading(true);
    const users = await AuthAPIs.getAllUsers();
    const customArr = [{ value: "", label: "Select" }];
    if (users) {
      const arr = [...users.data.data];
      arr?.forEach((item) => {
        const data = { value: item?.id, label: item?.firstName };
        if (item?.id !== userId) customArr.push(data);
      });
      setUsers(customArr);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  /**
   * Submit Handler for office Clear
   */
  const officeClearOrClose = async (type) => {
    if (!selectedIds[0]) {
      toast.error("No Tickets Selected");
      return;
    }
    setIsLoading(true);
    if (type === "office_clear") {
      const res = await TicketAPIs.officeClear({ ticketId: selectedIds });
      if (res) {
        toast.success("Office Clear Successfully");
      }
    } else if (type === "close") {
      const close = await TicketAPIs.closeTickets({ ticketId: selectedIds });
      if (close) {
        toast.success("Tickets Closed Successfully");
      }
    }
    setSelectedIds([]);
    setSearchedData([]);
    setIsLoading(false);
  };

  /**
   * Search Ticket on base of Pre completed by.
   */
  const serachTickets = async (preCompletedBy) => {
    setIsLoading(true);
    if (preCompletedBy !== "") {
      const search = await TicketAPIs.search({ preCompletedBy });
      if (search) {
        console.log("Searched Results === ", search.data);
        const customArr = [];
        const arr = [...search.data.data];
        arr?.forEach((item) => {
          const data = {
            id: item?.id,
            ticket: item?.ticketNo,
            location: `${item?.locationStreet} (${item?.ticketType})`,
            entered: formatDate(item?.createdAt),
            assign: formatDate(item?.updatedAt),
            assigntoo: item?.assignTo?.firstName,
            reassigned: formatDate(item?.preCompletedDate) || "hello",
            due: formatDate(item?.dueDate),
            code: "",
            oc: item?.officeClear,
          };
          customArr.push(data);
        });
        setSearchedData(customArr);
      }
    } else {
      setSearchedData([]);
    }

    setIsLoading(false);
  };

  return (
    <>
      <section>
        <Heading text={"Close Outstanding Tickets"} />

        <Card>
          <Form className="dashboard-form mb-4">
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group className="form-group">
                  <Form.Label>Pre Completed By</Form.Label>
                  <Select
                    options={users}
                    styles={customStyles}
                    defaultValue={users[0]}
                    // value={users.find(opt => opt.assignTo === values.assignTo)}
                    onChange={(selectedOption) => {
                      serachTickets(selectedOption.value);
                      setSelectedUserId(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Button
                  className="me-3"
                  onClick={() => {
                    officeClearOrClose("close");
                  }}
                >
                  <TbClipboardCheck />
                  Close Tickets
                </Button>
                <Button
                  onClick={() => {
                    officeClearOrClose("office_clear");
                  }}
                >
                  <TbClipboardCheck />
                  Office Clear
                </Button>
              </Col>
            </Row>
          </Form>
          <Row className="mb-3">
            <Col md={3}>
              <Form.Label style={{ fontSize: "14px", color: "#000" }}>
                Total Tickets Found{" "}
                <span style={{ color: "#757575" }}>{searchedData?.length}</span>
              </Form.Label>
            </Col>
          </Row>
          {searchedData[0] ? (
            <DataTable
              columns={columns}
              data={searchedData}
              setSelectedIds={setSelectedIds}
            />
          ) : (
            <p style={{ textAlign: "center" }}>No Results Available</p>
          )}
          <Footer />
        </Card>
      </section>
    </>
  );
};

export default CloseOutstanding;
