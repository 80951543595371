import { useSelector } from "react-redux";

// Base64 to Image
export function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isPermitted(path, department) {
  // console.log("Inside Helper Permissions == ", department?.permissions);
  if (department === null) {
    return true;
  } else if (department?.permissions && department?.permissions[0]) {
    const permissionsArr = [...department?.permissions];
    if (path === "/dashboard/billing") {
      const page = permissionsArr.findIndex((str) => str === "billing");
      if (page === -1) {
        return false;
      }
    }

    if (path === "/dashboard/alert-messages") {
      const page = permissionsArr.findIndex((str) => str === "alert messages");
      if (page === -1) {
        return false;
      }
    }

    if (path === "/dashboard/assign-tickets-by-map") {
      const page = permissionsArr.findIndex(
        (str) => str === "assign tickey by map"
      );
      if (page === -1) {
        return false;
      }
    }

    if (path === "/dashboard/assign-ticket") {
      const page = permissionsArr.findIndex((str) => str === "assign-ticket");
      if (page === -1) {
        return false;
      }
    }

    if (path === "/dashboard/query-search") {
      const page = permissionsArr.findIndex(
        (str) => str === "query-search-ticket"
      );
      if (page === -1) {
        return false;
      }
    }
  }
  return true;
}

export const formatDate = (data) => {
  // const date = new Date(data);
  const date = new Date((data && typeof data == 'string' && data.includes('Z')) ? data.slice(0, data.length-1) : data);  //TODO: temporary fix
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day < 10 ? "0" + day : day}, ${year}`;
};

export const formatTime = (data) => {
  const date = new Date((data && typeof data == 'string' && data.includes('Z')) ? data.slice(0, data.length-1) : data);  //TODO: temporary fix
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  hours = hours % 12;
  hours = hours || 12; // Handle midnight (0 hours)

  // Format hours with leading zero if needed
  const formattedHours = hours < 10 ? "0" + hours : hours;

  // Format minutes with leading zero if needed
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};
