import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DataTable from "Components/CustomTable";
import TicketAPIs from "../../APIs/ticket";
import SampleDataTable from "Components/DataTables";
const TicketOnSameStreet = ({ ticketData, ...props }) => {
  console.log(
    "DUCK",
    "TicketOnSameStreet:digNearestIntersection",
    ticketData?.digNearestIntersection
  );

  const [sameStreetData, setSameStreetData] = useState([]);

  useEffect(() => {
    if (ticketData?.digNearestIntersection !== undefined) {
      const getSameStreet = async () => {
        if (ticketData?.digNearestIntersection) {
          const search = await TicketAPIs.search({
            digNearestIntersection:
              ticketData?.digNearestIntersection?.split("&")[0],
          });

          const filteredData = search.data.data.filter(
            (item) => item.ticketNo !== ticketData?.ticketNo
          );

          console.log(
            "DUCK",
            "TicketOnSameRoadModal:useEffect:search",
            filteredData
          );

          setSameStreetData(filteredData);
        }
      };
      getSameStreet();
    }
  }, [ticketData?.digNearestIntersection]);

  return (
    <Modal {...props} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 className="text-dark mb-0">
            Ticket On Same Street - {ticketData?.ticketNo}
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <SampleDataTable
            columns={[
              {
                accessor: "ticketNo",
                Header: "Ticket No.",
              },
              {
                accessor: "digAddress",
                Header: "STREET",
              },
              {
                accessor: "digNearestIntersection",
                Header: "CROSS_STREET 1",
                Cell: ({ row }) => (
                  <p>{row.original?.digNearestIntersection?.split("&")[0]}</p>
                ),
              },
              {
                accessor: "locatorCrossStreet2",
                Header: "CROSS_STREET 2",
                Cell: ({ row }) => <p>{row.original?.locatorCrossStreet2}</p>,
              },
            ]}
            data={sameStreetData}
          />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>CLose</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TicketOnSameStreet;
