// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__SvLJ7 {
  padding: 20px;
  background: #fff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
}`, "",{"version":3,"sources":["webpack://./src/Components/Header/index.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,gBAAA;EACA,gDAAA;AACJ","sourcesContent":[".header{\n    padding: 20px;\n    background: #fff;\n    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__SvLJ7`
};
export default ___CSS_LOADER_EXPORT___;
