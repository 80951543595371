import API from "APIs/base";
import { ENDPOINT } from "config/constants";

const createCity = async (data) => {
  return await API.postMethod(ENDPOINT.city.create, true, data);
};

const getCities = async () => {
  return await API.getMethod(ENDPOINT.city.get, true);
};

const getCityById = async (id) => {
  return await API.getMethod(`${ENDPOINT.city.get}/${id}`, true);
};

const updateCityById = async (id) => {
  return await API.putMethod(`${ENDPOINT.city.update}/${id}`, true);
};

const deleteCityById = async (id) => {
  return await API.deleteMethod(`${ENDPOINT.city.update}/${id}`, true);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createCity,
  getCities,
  getCityById,
  updateCityById,
  deleteCityById,
};
