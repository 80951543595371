import Heading from "Components/Heading";
import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { BiSearch, BiExport, BiLike } from "react-icons/bi";
import Footer from "Components/Footer";
import DataTable from "Components/CustomTable";
import TicketAPIs from "../../APIs/ticket";
import Loader from "Components/Loader";
import SearchForm from "Components/SearchForm";
import { toast } from "react-toastify";
import SampleDataTable from "Components/DataTables";
import { formatDate } from "Helper/Converters";
import * as XLSX from "xlsx-js-style";

const SearchTicket = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchedData, setSearchedData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const handleSelectionChange = (selectedIds) => {
    setSelectedIds(selectedIds);
  };

  const columns = [
    {
      accessor: "ticketNo",
      Header: "Ticket No",
      Cell: ({ row }) => (
        <a
          onClick={(e) => {
            e.preventDefault();
            const url = `/dashboard/ticket-detail/${row.original.ticketNo}`;
            // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
            const windowName = `popUpWindow_${row.original.ticketNo}`;
            const windowSize = "width=800,height=600";
            window.open(url, windowName, windowSize);
          }}
          href={`/dashboard/ticket-detail/${row.original.ticketNo}`}
          rel="noopener noreferrer"
          className={`badge ${
            row.original.ticketType == "EMERGENCY" ||
                row.original.priority == "EMERGENCY"
                  ? 'emergency'
                  : row.original.ticketType == "MULTIPLE" ||
                    row.original.priority == "MULTIPLE"
                  ? 'multiple'
                  : row.original.ticketType == "PRIORITY" ||
                    row.original.priority == "PRIORITY"
                  ? 'priority'
                  : row.original.ticketType == "PROJECT WORK" ||
                    row.original.priority == "PROJECT WORK"
                  ? 'projectWork'
                  : row.original.ticketType == "STANDARD" ||
                    row.original.priority == "STANDARD"
                  ? 'standard'
                  : 'default'
          }`}
        >
          {row.original.ticketNo}
        </a>
      ),
    },
    { accessor: "location", Header: "Location" },
    { accessor: "entered", Header: "Entered" },
    {
      accessor: "assigntoo",
      Header: "Assigned To",
      Cell: ({ row }) => <p>{row.original.assigntoo?.firstName}</p>,
    },
    {
      accessor: "assign",
      Header: "Assigned Date",
    },
    {
      accessor: "reassinged",
      Header: "Reassigned To",
      Cell: ({ row }) => <p>{row.original.reassigned?.firstName}</p>,
    },
    { accessor: "reassingndate", Header: "Reassigned Date" },
    { accessor: "due", Header: "Due" },
    { accessor: "completedDate", Header: "Completed" },
    {
      accessor: "code",
      Header: "Station Code",
      Cell: ({ row }) => (
        <p>{row.original.code?.map((item) => item.name).join(", ")}</p>
      ),
    },
    { accessor: "segmet", Header: "Segment" },
    {
      accessor: "isAssignPerTicket",
      Header: "Assign Per Ticket",
      Cell: ({ row }) => (
        <p>
          {row.original.isAssignPerTicket ? (
            <BiLike color="green" size={30} />
          ) : (
            <BiLike color="red" size={30} />
          )}
        </p>
      ),
    },
  ];

  /**
   * Submit Handler for office Clear
   */
  const officeClearOrClose = async (type) => {
    if (!selectedIds && !selectedIds[0]) {
      toast.error("No Tickets Selected");
      return;
    }
    console.log("Selected id =", selectedIds);
    if (type === "office_clear") {
      const res = await TicketAPIs.officeClear({ ticketId: selectedIds });
      if (res) {
        toast.success("Office Clear Successfully");
      }
    } else if (type === "close") {
      const close = await TicketAPIs.closeTickets({ ticketId: selectedIds });
      if (close) {
        toast.success("Tickets Closed Successfully");
      }
    }
    setSelectedIds([]);
    setSearchedData([]);
    setIsLoading(false);
  };

  const formatDateForExcel = () => {
    let formattedData = [
      [
        {
          v: "Ticket #",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Address",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Contractor",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Type of Ticket",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Entered Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Assigned Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Assigned Too",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Due Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Completed Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Station Code",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Segment",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Office Clear",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Locate City",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Caller Name",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Type of Work",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Per-Ticket",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
      ],
    ];
    searchedData.forEach((item) => {
      console.log("itemmm", item);
      let reason = "";
      // if (item.attemptedrescheduledate || item.renegotiateddate || item.completeddate) {
      //   const days = parseInt((new Date(item.attemptedrescheduledate || item.renegotiateddate || item.completeddate).getTime() - new Date(item.compliancedate).getTime()) / 86400000)
      //   reason = item.renegotiateddate ? `Rescheduled ${days} days(s) after entered date regulation` : `Completed ${days} day(s) after regulation`
      // }
      formattedData.push([
        { v: item.ticket },
        { v: item.digAddress },
        { v: item.companyName },
        { v: item.ticketType },
        { v: item.entered ? formatDate(item.entered) : "" },
        { v: item.assign },
        { v: item.assigntoo },
        { v: item.due ? formatDate(item.due) : "" },
        { v: item.completedDate ? formatDate(item.completedDate) : "" },
        { v: item.code?.map((item) => item.name).join(", ") },
        { v: item.segmet },
        { v: item.isOfficeClear },
        { v: item.city || "" },
        { v: item.contractorName },
        { v: item.typeOfWork },
        { v: item.isAssignPerTicket },
      ]);
    });
    return formattedData;
  };

  const exportToExcel = () => {
    if (!(searchedData.length > 0)) {
      toast.error("No data found yet!");
      return;
    }
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert your data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(formatDateForExcel());

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    // Export the workbook
    XLSX.writeFile(workbook, "Searched_Tickets.xlsx");
  };

  return (
    <>
      {isLoading && <Loader isLoading={isLoading} />}
      <section>
        <Heading text={"Query/Search Ticket"} btn />

        <Card>
          <SearchForm
            setSearchedData={setSearchedData}
            setIsLoading={setIsLoading}
            loadDataOnMount={true}
          // filters={{ isOfficeClear: false }}
          />
          {/* <Row className="mb-5">
            <Col md={12}>
              <Button
                className="me-3"
                onClick={() => {
                  officeClearOrClose("close");
                }}
              >
                <BiSearch />
                Close Tickets
              </Button>
              <Button
                onClick={() => {
                  officeClearOrClose("office_clear");
                }}
              >
                <BiExport />
                Office Clear
              </Button>
            </Col>
          </Row> */}
            <SampleDataTable
              columns={columns}
              data={searchedData}
              onSelectionChange={handleSelectionChange}
            />
          <Col md={12} className="d-flex justify-content-end mb-3">
            <Button onClick={exportToExcel}>
              <BiExport />
              Export
            </Button>
          </Col>
          <Footer />
        </Card>
      </section>
    </>
  );
};

export default SearchTicket;
