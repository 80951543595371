import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Tab, Tabs, Row } from "react-bootstrap";
import TicketAPIs from "../../../APIs/ticket";
import SampleDataTable from "Components/DataTables";
import { formatDate } from "Helper/Converters";
import MapBox from "Components/MapBox";
import Select from "react-select";
import { MdDateRange } from "react-icons/md";
import { MdOutlineRefresh } from "react-icons/md";
import AuthAPIs from "../../../APIs/auth";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import CustomDatePicker from "Components/CustomDatePicker";

const UserTicketDetail = ({ show, onHide, userId, selectedTab = "tab1" }) => {
  const [key, setKey] = useState(selectedTab);
  const [isLoading, setIsLoading] = useState(false);
  const [searchedData, setSearchedData] = useState([]);
  const [unAssignedTicket, setUnAssignedTicket] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [endDate, setEndDate] = useState(new Date());

  const handleSelectionChange = (selectedIds) => {
    console.log("Selected IDs:", selectedIds);
    setSelectedIds(selectedIds);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px", // Set the minimum height as needed
    }),
  };

  const getUserTickets = async () => {
    setIsLoading(true);
    const res = await TicketAPIs.searchTicket({
      assignTo: userId,
    });
    if (res) {
      // console.log("DUCK", "UserTicketDetail:getUserTickets", res.data.data);
      // const customArr = [];
      // res.data.data?.forEach((item) => {
      //   const data = {
      //     id: item?.id,
      //     ticket: item?.ticketNo,
      //     location: `${
      //       item?.digAddress != null || item?.digAddress !== undefined
      //         ? item?.digAddress
      //         : ""
      //     } (${item?.ticketType})`,
      //     assigntoo: item?.assignTo?.firstName,
      //     assignUserID: item?.assignTo?.id,
      //     reassinged: item?.reAssignTo?.firstName,
      //     due: item?.workToBeginDate ? formatDate(item?.workStartworkToBeginDateDate) : "",
      //     code: item?.code?.[0]?.name,
      //     segmet: item?.digSegments != null ? item?.digSegments : "0",
      //     city: item?.city,
      //     lat: item?.locationLat,
      //     lng: item?.locationLng,
      //     preCompletedDate: item?.preCompletedDate
      //       ? formatDate(item?.preCompletedDate)
      //       : "",
      //     completedDate: item?.completedDate
      //       ? formatDate(item?.completedDate)
      //       : "",
      //     reassingndate: item?.reAssignDate
      //       ? formatDate(item?.reAssignDate)
      //       : "",
      //     closeDate: item?.closeDate ? formatDate(item?.closeDate) : "",
      //     entered: item?.createdAt ? formatDate(item?.createdAt) : "",
      //     assign: item?.assignDate ? formatDate(item?.assignDate) : "",
      //     isOfficeClear: item?.isOfficeClear,
      //   };
      //   customArr.push(data);
      // });
      // console.log("DUCK", "SearchForm:customArr", customArr);
      setSearchedData(res.data?.data || []);

      const sortedTicket = res.data.data?.map((item, index) => {
        console.log(item);
        return {
          id: index,
          ticketId: item.id,
          ticketNo: item.ticketNo,
          label: `${
            item?.digAddress != null || item?.digAddress !== undefined
              ? item?.digAddress
              : ""
          } (${item?.ticketType})`,
          lat: parseFloat(item.lat), // Convert to number
          lng: parseFloat(item.lng), // Convert to number
        };
      });
      setUnAssignedTicket(sortedTicket);
    }
    setIsLoading(false);
  };

  const getUsers = async () => {
    const users = await AuthAPIs.getAllUsers();
    const customArr = [{ value: "", label: "Select" }];
    if (users) {
      const arr = [...users.data.data];
      arr?.forEach((item) => {
        const data = { value: item?.id, label: item?.firstName };
        if (item?.id !== userId) customArr.push(data);
      });
      setUsers(customArr);
    }
  };

  useEffect(() => {
    setSearchedData([]);
    setUnAssignedTicket([]);
    getUserTickets();
    getUsers();
  }, [userId]);

  useEffect(() => {
    setKey(selectedTab);
  }, [selectedTab]);

  const reAssignTicket = async () => {
    if (!selectedUser) {
      toast.error("Please Select User to Assign");
      return;
    }
    if (!selectedIds[0]) {
      toast.error("No Tickets Selected.");
      return;
    }
    setIsLoading(true);
    const res = await TicketAPIs.assignReassignTicket({
      ticketId: selectedIds,
      userId: selectedUser,
      type: "re_assign",
      reAssignDate: endDate,
    });
    if (res) {
      toast.success("Ticket Re-assigned Successfully");
      setSelectedUser(null);
      setSelectedIds([]);
      getUserTickets();
    }
    setIsLoading(false);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="custom-modal-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          User Ticket Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          id="user-ticket-detail-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="tab1" title="Map">
            {isLoading && <p style={{ textAlign: "center" }}>Loading...</p>}
            {searchedData.length > 0 ? (
              <MapBox
                locations={unAssignedTicket}
                enablePolygon={false}
                enableTicketDetail={true}
              />
            ) : null}
          </Tab>
          <Tab eventKey="tab2" title="Tickets">
            <Form>
              <Row>
                <div className="headingBox">
                  <h6>Reassign Tickets</h6>
                </div>
                <Col className="column">
                  <Form.Group className="form-group">
                    <Form.Label>Reassign From</Form.Label>
                    <Select
                      options={users}
                      styles={customStyles}
                      defaultValue={users[0]}
                      onChange={(selectedOption) => {
                        setSelectedUser(selectedOption.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col className="column">
                  <Form.Group className="form-group">
                    <Form.Label>Reassigned Date</Form.Label>
                    <div className="datepicker_box">
                      <CustomDatePicker
                        selected={endDate}
                        onSelect={(date) => setEndDate(date)}
                        dateFormat="MM/dd/yyyy"
                      />
                      <span>
                        <MdDateRange />
                      </span>
                    </div>
                  </Form.Group>
                </Col>

                <Col className="mb-4 column" style={{ maxWidth: "160px" }}>
                  <Button
                    style={{ minHeight: "44px", marginTop: "30px" }}
                    onClick={() => {
                      reAssignTicket();
                    }}
                  >
                    <MdOutlineRefresh />
                    Reassign
                  </Button>
                </Col>
              </Row>
            </Form>
            <SampleDataTable
              columns={[
                {
                  accessor: "ticket",
                  Header: "Ticket No",
                  Cell: ({ row }) => (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        const url = `/dashboard/ticket-detail/${row.original.ticketNo}`;
                        // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
                        const windowName = `popUpWindow_${row.original.ticketNo}`;
                        const windowSize = "width=800,height=600";
                        window.open(url, windowName, windowSize);
                      }}
                      href={`/dashboard/ticket-detail/${row.original.ticketNo}`}
                      rel="noopener noreferrer"
                      className={`badge ${
                        row.original.ticketType == "EMERGENCY" ||
                        row.original.priority == "EMERGENCY"
                          ? "emergency"
                          : row.original.ticketType == "MULTIPLE" ||
                            row.original.priority == "MULTIPLE"
                          ? "multiple"
                          : row.original.ticketType == "PRIORITY" ||
                            row.original.priority == "PRIORITY"
                          ? "priority"
                          : row.original.ticketType == "PROJECT WORK" ||
                            row.original.priority == "PROJECT WORK"
                          ? "projectWork"
                          : row.original.ticketType == "STANDARD" ||
                            row.original.priority == "STANDARD"
                          ? "standard"
                          : "default"
                      }`}
                    >
                      {row.original.ticketNo}
                    </a>
                  ),
                },
                { accessor: "location", Header: "Location" },
                { accessor: "entered", Header: "Entered" },
                {
                  accessor: "assigntoo",
                  Header: "Assigned To",
                  Cell: ({ row }) => <p>{row.original.assigntoo?.firstName}</p>,
                },
                {
                  accessor: "assign",
                  Header: "Assigned Date",
                },
                {
                  accessor: "reassinged",
                  Header: "Reassigned To",
                  Cell: ({ row }) => (
                    <p>{row.original.reassigned?.firstName}</p>
                  ),
                },
                { accessor: "reassingndate", Header: "Reassigned Date" },
                { accessor: "due", Header: "Due" },
                {
                  accessor: "code",
                  Header: "Station Code",
                  Cell: ({ row }) => (
                    <p>
                      {Array.isArray(row.original?.code)
                        ? row.original?.code.map((item) => item.name).join(", ")
                        : row.original?.code || ""}
                    </p>
                  ),
                },
                { accessor: "segmet", Header: "Segment" },
              ]}
              data={searchedData}
              onSelectionChange={handleSelectionChange}
              isLoading={isLoading}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserTicketDetail;
