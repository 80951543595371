// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Heading_iconBtn__-BMLJ span {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background-color: #d9eaf5;
  color: #0073ba;
  padding: 3px;
  font-size: 10px;
  border-radius: 3px;
  position: relative;
  z-index: 999;
}
.Heading_iconBtn__-BMLJ span:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 4px solid transparent;
  border-top: 12px solid #d9eaf5;
  bottom: -5px;
  left: 0;
  transform: rotate(45deg);
}`, "",{"version":3,"sources":["webpack://./src/Components/Heading/index.module.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,cAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;AAAJ;AACI;EACI,kBAAA;EACA,WAAA;EACA,QAAA;EACA,SAAA;EACA,kCAAA;EACA,mCAAA;EACA,8BAAA;EACA,YAAA;EACA,OAAA;EACA,wBAAA;AACR","sourcesContent":[".iconBtn {\n  span {\n    margin-right: 10px;\n    width: 20px;\n    height: 20px;\n    background-color: #d9eaf5;\n    color: #0073ba;\n    padding: 3px;\n    font-size: 10px;\n    border-radius: 3px;\n    position: relative;\n    z-index: 999;\n    &:after {\n        position: absolute;\n        content: \"\";\n        width: 0;\n        height: 0;\n        border-left: 6px solid transparent;\n        border-right: 4px solid transparent;\n        border-top: 12px solid #d9eaf5;\n        bottom: -5px;\n        left: 0;\n        transform: rotate(45deg);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconBtn": `Heading_iconBtn__-BMLJ`
};
export default ___CSS_LOADER_EXPORT___;
