// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminDashboard_card__VS5yF {
  flex-direction: row !important;
  align-items: center;
  gap: 12px;
  border-left: 3px solid #0073BA !important;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/AdminDashboard/index.module.scss"],"names":[],"mappings":"AAAA;EACG,8BAAA;EACA,mBAAA;EACA,SAAA;EACA,yCAAA;EACA,YAAA;AACH","sourcesContent":[".card{\n   flex-direction: row !important;\n   align-items: center;\n   gap: 12px;\n   border-left: 3px solid #0073BA !important;\n   height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `AdminDashboard_card__VS5yF`
};
export default ___CSS_LOADER_EXPORT___;
