import Heading from "Components/Heading";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import TicketAPIs from "../../APIs/ticket";
import SampleDataTable from "Components/DataTables";
import SearchForm from "Components/SearchForm";
import { toast } from "react-toastify";

const OfficeClear = () => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [ticketStationCodeSelections, setTicketStationCodeSelections] =
    useState({});
  const [user, setUser] = useState();
  const [refresh, setRefresh] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px", // Set the minimum height as needed
    }),
  };

  const columns = [
    {
      accessor: "ticketNo",
      Header: "Ticket No",
      Cell: ({ row }) => (
        <a
          onClick={(e) => {
            e.preventDefault();
            const url = `/dashboard/ticket-detail/${row.original.ticketNo}`;
            // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
            const windowName = `popUpWindow_${row.original.ticketNo}`;
            const windowSize = "width=800,height=600";
            window.open(url, windowName, windowSize);
          }}
          href={`/dashboard/ticket-detail/${row.original.ticketNo}`}
          rel="noopener noreferrer"
          className={`badge ${row.original.ticketType == "EMERGENCY" ||
              row.original.priority == "EMERGENCY"
              ? 'emergency'
              : row.original.ticketType == "MULTIPLE" ||
                row.original.priority == "MULTIPLE"
                ? 'multiple'
                : row.original.ticketType == "PRIORITY" ||
                  row.original.priority == "PRIORITY"
                  ? 'priority'
                  : row.original.ticketType == "PROJECT WORK" ||
                    row.original.priority == "PROJECT WORK"
                    ? 'projectWork'
                    : row.original.ticketType == "STANDARD" ||
                      row.original.priority == "STANDARD"
                      ? 'standard'
                      : 'default'
            }`}
        >
          {row.original.ticketNo}
        </a>
      ),
    },
    { accessor: "location", Header: "Location" },
    { accessor: "entered", Header: "Entered" },

    { accessor: "due", Header: "Due" },
    {
      accessor: "code",
      Header: "Station Code",
      Cell: ({ row }) => (
        <p>{row.original.code?.map((item) => item.name).join(", ")}</p>
      ),
    },
    { accessor: "segmet", Header: "Segment" },
  ];

  const handleSelectionChange = (selectedIds) => {
    console.log("Selected IDs:", selectedIds);
    setSelectedIds(selectedIds);
  };

  const handleStationCodeChange = (ticketId, stationCodeId) => {
    const selections = { ...ticketStationCodeSelections };
    const ticketSelections = selections[ticketId] || [];

    if (ticketSelections.includes(stationCodeId)) {
      selections[ticketId] = ticketSelections.filter(
        (id) => id !== stationCodeId
      );
    } else {
      selections[ticketId] = [...ticketSelections, stationCodeId];
    }

    setTicketStationCodeSelections(selections);
  };

  const handleOfficeClear = async () => {
    // Ensure there are selected tickets.
    if (selectedIds.length === 0) {
      toast.warning("Select at least one ticket.");
      return;
    }

    // Check that each selected ticket has at least one station code selected.
    const allTicketsValid = selectedIds.every(
      (ticketId) =>
        ticketStationCodeSelections[ticketId] &&
        ticketStationCodeSelections[ticketId].length > 0
    );

    if (!allTicketsValid) {
      toast.warning(
        "Please select at least one station code for each selected ticket."
      );
      return;
    }

    // Initialize a flag to track success.
    let allSuccessful = true;

    // Process each valid ticket.
    for (const ticketId of selectedIds) {
      const stationCodeIds = ticketStationCodeSelections[ticketId];
      const totalStationCodes =
        searchedData
          .find((data) => data.id === ticketId)
          ?.code?.filter((item) => item.isOfficeClear).length || 0;
      try {
        console.log("DUCK", "handleOfficeClear:ticketId", ticketId);
        console.log("DUCK", "handleOfficeClear:userId", user?.id);
        console.log(
          "DUCK",
          "handleOfficeClear:stationCodeIds",
          ticketStationCodeSelections[ticketId]
        );
        // Decide to complete the ticket or send to locate based on station code selection
        if (stationCodeIds.length === totalStationCodes) {
          console.log("DUCK", "completing ticket");
          // Complete the ticket if all its station codes are selected
          await TicketAPIs.completeTicket({
            ticketId: [ticketId],
            userId: user?.id,
          });
        } else {
          console.log("DUCK", "sending ticket to locate");
          // Send to locate if not all station codes are selected
          await TicketAPIs.sendToLocate({
            ticketId: [ticketId],
          });
        }

        for (const stationCodeId of stationCodeIds) {
          try {
            await TicketAPIs.officeClearStation({
              ticketId,
              stationCodeId, // Modified to match the expected singular stationCodeId
              isOfficeCleared: true,
              userId: user?.id,
            });
          } catch (innerError) {
            console.error(
              "Error clearing office for station code:",
              stationCodeId,
              "in ticket:",
              ticketId,
              innerError
            );
            allSuccessful = false;
          }
        }
      } catch (error) {
        console.error("Error clearing office for ticket:", ticketId, error);
        allSuccessful = false;
      }
    }

    if (allSuccessful) {
      // toast.success(
      //   "All selected tickets and station codes have been successfully cleared!"
      // );
      const selectedTicketsNumbers = searchedData
        .filter((item) => selectedIds.indexOf(item.id) > -1)
        .map((item) => item.ticket);
      setSuccessMsg(
        `Following Tickets ${selectedTicketsNumbers.join(
          ","
        )} has been cleared.`
      );
      setTimeout(() => {
        setRefresh(!refresh);
      }, 1000);
    } else {
      toast.error(
        "One or more tickets could not be cleared. Please check the logs for more information."
      );
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("DUCK", "TicketDetails:user", user);
    setUser(user);
  }, []);

  return (
    <>
      <section>
        <Heading text={"Office Clear"} />
        {successMsg && (
          <div className="successMsg">
            <strong style={{ margin: 0, color: "rgb(0,120,0)" }}>
              {successMsg}
            </strong>
            <MdClose
              size={20}
              color={"gray"}
              onClick={() => setSuccessMsg("")}
              style={{ position: "absolute", top: 10, right: 10 }}
            />
          </div>
        )}
        <Card>
          <Row>
            <SearchForm
              setSearchedData={setSearchedData}
              setIsLoading={setIsLoading}
              filters={{ isOfficeClear: true }}
              loadDataOnMount={true}
              refresh={refresh}
              from='officeClear'
            />
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Button className="me-3" onClick={handleOfficeClear}>
                <AiOutlineCheckCircle />
                Office Clear
              </Button>
              <Button
                onClick={async () => {
                  if (selectedIds?.length > 0) {
                    console.log("DUCK", "selectedIds", selectedIds);
                    await TicketAPIs.sendToLocate({
                      ticketId: selectedIds,
                    });
                    setRefresh(!refresh);
                    const selectedTicketsNumbers = searchedData
                      .filter((item) => selectedIds.indexOf(item.id) > -1)
                      .map((item) => item.ticket);
                    setSuccessMsg(
                      `Following Tickets ${selectedTicketsNumbers.join(
                        ","
                      )} has been send to locate.`
                    );
                  } else {
                    toast.warning("Select atleast one ticket");
                  }
                }}
              >
                <AiOutlineCheckCircle />
                Send to Locate
              </Button>
            </Col>
          </Row>
          <SampleDataTable
            columns={columns}
            data={searchedData}
            onSelectionChange={handleSelectionChange}
          />
        </Card>
      </section>
    </>
  );
};

export default OfficeClear;
