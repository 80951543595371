import Heading from "Components/Heading";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { MdDateRange } from "react-icons/md";
import { BiSearch, BiExport, BiLike } from "react-icons/bi";
import StationCodesAPIs from "../../APIs/station-code";
import { Formik } from "formik";
import * as Yup from "yup";
import Footer from "Components/Footer";
import TicketAPIs from "../../APIs/ticket";
import SampleDataTable from "Components/DataTables";
import { formatDate } from "Helper/Converters";
import * as XLSX from "xlsx-js-style";
import { toast } from "react-toastify";
import CustomDatePicker from "Components/CustomDatePicker";

const DATE_TYPE_OPTIONS = [
  { value: "", label: "Select" },
  { value: "completeDate", label: "Completed Date" },
  { value: "enteredDate", label: "Entered Date" },
  { value: "dueDate", label: "Due Date" },
  { value: "assignDate", label: "Assigned Date" },
  { value: "cancelDate", label: "Cancelled Date" },
];

const INITIAL_VALUES = {
  stationCodeIds: [],
  dateType: DATE_TYPE_OPTIONS[0],
  fromDate: null,
  toDate: null,
  nofDays: 5,
  isCompliant: false,
};

const ValidationSchema = Yup.object().shape({
  dateType: Yup.object()
    .shape({
      value: Yup.string().required("Date Type is required"),
    })
    .required("Date Type is required"),
  fromDate: Yup.date().required("Start Date is required"),
  toDate: Yup.date().required("End Date is required"),
  nofDays: Yup.number().min(1, "Business Days must be atleast 1"),
});

const NonComplaintReport = () => {
  const [checkedItems, setCheckedItems] = useState({});
  const [stationCodes, setStationCodes] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [loading, setLoading] = useState(false);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px", // Set the minimum height as needed
    }),
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems({ ...checkedItems, [name]: checked });
  };

  const options = [
    { value: "0", label: "Open this select menu" },
    { value: "1", label: "One" },
    { value: "2", label: "Two" },
    { value: "3", label: "Three" },
  ];

  const getAllStationCodes = async () => {
    const stationCodes = await StationCodesAPIs.getStationCode();
    if (stationCodes.data?.success) {
      console.log("getAllStationCodes:stationCodes", stationCodes.data.data);
      setStationCodes(
        stationCodes.data.data
          ?.filter((item) => item.isFilterable)
          .sort((a, b) => a.name.localeCompare(b.name)) || []
      );
    }
  };

  useEffect(() => {
    getAllStationCodes();
  }, []);

  function addBusinessDays(startDate, numberOfDays) {
    const date = new Date(startDate); // Ensure startDate is a Date object
    const weeks = Math.floor(numberOfDays / 5);
    const remainingDays = numberOfDays % 5;
    const dayOfWeek = date.getDay(); // Sunday - Saturday : 0 - 6

    let newDate = new Date(date);
    newDate.setDate(date.getDate() + weeks * 7);

    let adjustedRemainingDays = remainingDays;

    // Calculate the day of the week after adding the remaining days
    const tempDayOfWeek = (dayOfWeek + remainingDays) % 7;

    // Adjust the remaining days if it falls on the weekend
    if (tempDayOfWeek === 0) {
      // Sunday
      adjustedRemainingDays += 2;
    } else if (tempDayOfWeek === 6) {
      // Saturday
      adjustedRemainingDays += 1;
    }

    newDate.setDate(newDate.getDate() + adjustedRemainingDays);

    return newDate;
  }

  function getBusinessDaysNum(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    let count = 0;
    let currentDate = new Date(start);

    while (currentDate <= end) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        // Exclude weekends
        count++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return count;
  }

  function getComplianceNote(
    enteredDate,
    renegociatedDate,
    completedDate,
    renegociatedAttemptDate,
    numBusinessDays
  ) {
    let retVal = "";
    let reComplianceDate,
      reComplianceNumDays,
      complianceDate,
      complianceNumDays;

    if (renegociatedDate != null) {
      reComplianceDate = addBusinessDays(renegociatedDate, 0);
      reComplianceNumDays = getBusinessDaysNum(
        renegociatedDate,
        numBusinessDays
      );
    }

    complianceDate = addBusinessDays(enteredDate, numBusinessDays);
    complianceNumDays = getBusinessDaysNum(enteredDate, numBusinessDays);

    if (renegociatedAttemptDate != null) {
      if (
        (new Date(renegociatedAttemptDate) - new Date(complianceDate)) /
          (1000 * 60 * 60 * 24) >
        complianceNumDays
      ) {
        retVal =
          "Rescheduled " +
          Math.ceil(
            (new Date(renegociatedAttemptDate) - new Date(complianceDate)) /
              (1000 * 60 * 60 * 24)
          ) +
          " day(s) after entered date regulation";
      } else {
        if (
          completedDate != null &&
          (new Date(completedDate) - new Date(reComplianceDate)) /
            (1000 * 60 * 60 * 24) >
            0
        ) {
          retVal =
            "Completed " +
            Math.ceil(
              (new Date(completedDate) - new Date(reComplianceDate)) /
                (1000 * 60 * 60 * 24)
            ) +
            " day(s) after regulation";
        } else if (
          completedDate === "" &&
          (new Date() - new Date(reComplianceDate)) / (1000 * 60 * 60 * 24) > 0
        ) {
          retVal =
            "Not Completed " +
            Math.ceil(
              (new Date() - new Date(reComplianceDate)) / (1000 * 60 * 60 * 24)
            ) +
            " day(s) after regulation";
        }
      }
    } else {
      if (
        completedDate !== "" &&
        (new Date(completedDate) - new Date(complianceDate)) /
          (1000 * 60 * 60 * 24) >
          0
      ) {
        retVal =
          "Completed " +
          Math.ceil(
            (new Date(completedDate) - new Date(complianceDate)) /
              (1000 * 60 * 60 * 24)
          ) +
          " day(s) after regulation";
      } else if (
        completedDate === "" &&
        (new Date() - new Date(complianceDate)) / (1000 * 60 * 60 * 24) > 0
      ) {
        retVal =
          "Not Completed " +
          Math.ceil(
            (new Date() - new Date(complianceDate)) / (1000 * 60 * 60 * 24)
          ) +
          " day(s) after regulation";
      }
    }

    return retVal;
  }

  const handleSubmit = async (values) => {
    values = {
      ...values,
      dateType: values.dateType.value,
      nofDays: values.nofDays.toString(),
    };
    setLoading(true);
    const search = await TicketAPIs.nonCompliantReport(values);
    if (search.data?.success) {
      const arr =
        search.data.data.map((item) => ({
          ...item,
          compliancedate: addBusinessDays(
            item.renegotiateddate || item.entereddate,
            item.renegotiateddate ? 0 : values.nofDays
          ),
          reason: getComplianceNote(
            item.entereddate,
            item.renegotiateddate,
            item.completeddate,
            item.renegotiatedattempteddate,
            values.nofDays
          ),
        })) || [];
      setSearchedData(arr);
    }
    setLoading(false);
  };

  const cleanAddress = (address) => {
    return address
      .split(",")
      .map((part) => part.trim())
      .filter((part) => part.length > 0)
      .join(", ");
  };

  const columns = [
    {
      accessor: "ticket_no",
      Header: "Ticket No",
      Cell: ({ row }) => (
        <a
          onClick={(e) => {
            e.preventDefault();
            const url = `/dashboard/ticket-detail/${row.original.ticket_no}`;
            // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
            const windowName = `popUpWindow_${row.original.ticket_no}`;
            const windowSize = "width=800,height=600";
            window.open(url, windowName, windowSize);
          }}
          href={`/dashboard/ticket-detail/${row.original.ticket_no}`}
          rel="noopener noreferrer"
          className={`badge ${
            row.original.ticketType == "EMERGENCY" ||
            row.original.priority == "EMERGENCY"
              ? "emergency"
              : row.original.ticketType == "MULTIPLE" ||
                row.original.priority == "MULTIPLE"
              ? "multiple"
              : row.original.ticketType == "PRIORITY" ||
                row.original.priority == "PRIORITY"
              ? "priority"
              : row.original.ticketType == "PROJECT WORK" ||
                row.original.priority == "PROJECT WORK"
              ? "projectWork"
              : row.original.ticketType == "STANDARD" ||
                row.original.priority == "STANDARD"
              ? "standard"
              : "default"
          }`}
        >
          {row.original.ticket_no}
        </a>
      ),
    },
    {
      Header: "Address",
      Cell: ({ row }) => <p>{cleanAddress(row.original.ticket_dig_address)}</p>,
    },
    { accessor: "contractorname", Header: "Contractor" },
    {
      Header: "Entered Date",
      Cell: ({ row }) => (
        <p>
          {row.original.entereddate ? formatDate(row.original.entereddate) : ""}
        </p>
      ),
    },
    {
      Header: "Compliance Date",
      Cell: ({ row }) => (
        <p>
          {row.original.compliancedate
            ? formatDate(row.original.compliancedate)
            : ""}
        </p>
      ),
    },
    {
      Header: "Renegotiated Date",
      Cell: ({ row }) => (
        <p>
          {row.original.renegotiateddate
            ? formatDate(row.original.renegotiateddate)
            : ""}
        </p>
      ),
    },
    {
      Header: "Completed Date",
      Cell: ({ row }) => (
        <p>
          {row.original.completeddate
            ? formatDate(row.original.completeddate)
            : ""}
        </p>
      ),
    },
    {
      Header: "Renegotiated Attempt Date",
      Cell: ({ row }) => (
        <p>
          {row.original.renegotiatedattempteddate
            ? formatDate(row.original.renegotiatedattempteddate)
            : ""}
        </p>
      ),
    },
    { accessor: "reason", Header: "Non Compliant Reason" },
  ];

  const formatDateForExcel = () => {
    let formattedData = [
      [
        {
          v: "Ticket No.",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Address",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Contractor",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Entered Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Compliance Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Renegotiated Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Completed Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Renegotiated Attempt Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Non Compliant Reason",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
      ],
    ];
    searchedData.forEach((item) => {
      formattedData.push([
        { v: item.ticket_no },
        { v: item.ticket_dig_address },
        { v: item.contractorname },
        { v: item.entereddate ? formatDate(item.entereddate) : "" },
        { v: item.compliancedate ? formatDate(item.compliancedate) : "" },
        { v: item.renegotiateddate ? formatDate(item.renegotiateddate) : "" },
        { v: item.completeddate ? formatDate(item.completeddate) : "" },
        {
          v: item.renegotiatedattempteddate
            ? formatDate(item.renegotiatedattempteddate)
            : "",
        },
        { v: item.reason },
      ]);
    });
    return formattedData;
  };

  const exportToExcel = () => {
    if (!(searchedData.length > 0)) {
      toast.error("No data found yet!");
      return;
    }
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert your data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(formatDateForExcel());

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    // Export the workbook
    XLSX.writeFile(workbook, "Non_Compliance_Report.xlsx");
  };

  const renderPercentage = useMemo(() => {
    return parseInt(
      (searchedData.filter((item) => !item.reason).length /
        searchedData.length) *
        100
    );
  }, [searchedData]);

  return (
    <>
      <section>
        <Heading text={"Non Compliance Report"} />

        <Card>
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              /* and other goodies */
            }) => {
              return (
                <Form className="dashboard-form" onSubmit={handleSubmit}>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Label>Station Codes</Form.Label>
                        <div className="checkboxHolder">
                          {stationCodes.map((item, index) => (
                            <Form.Check
                              type="checkbox"
                              label={item.name}
                              name={`stationCodes-${index}`}
                              checked={
                                values.stationCodeIds.findIndex(
                                  (id) => id == item.id
                                ) > -1
                              }
                              onChange={(e) => {
                                if (
                                  values.stationCodeIds.findIndex(
                                    (id) => id == item.id
                                  ) > -1
                                )
                                  setFieldValue(
                                    "stationCodeIds",
                                    values.stationCodeIds.filter(
                                      (id) => id != item.id
                                    )
                                  );
                                else
                                  setFieldValue("stationCodeIds", [
                                    ...values.stationCodeIds,
                                    item.id,
                                  ]);
                              }}
                            />
                          ))}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col className="column">
                      <Form.Group className="form-group">
                        <Form.Label>Date Type</Form.Label>
                        <Select
                          name="dateType"
                          options={DATE_TYPE_OPTIONS}
                          defaultValue={DATE_TYPE_OPTIONS[0]}
                          styles={customStyles}
                          onChange={(val) => setFieldValue("dateType", val)}
                          onBlur={() => setFieldTouched("dateType", true, true)}
                        />
                        {touched.dateType && errors.dateType?.value && (
                          <span className="error-msg">
                            {errors.dateType.value}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col className="column">
                      <Form.Group className="form-group">
                        <Form.Label>Start Date</Form.Label>
                        <div className="datepicker_box">
                          <CustomDatePicker
                            name="fromDate"
                            selected={values.fromDate}
                            onSelect={(date) => {
                              setFieldValue("fromDate", date);
                              setFieldValue("toDate", null);
                            }}
                            dateFormat="MM/dd/yyyy" // Adjust the date format as needed
                            onBlur={() =>
                              setFieldTouched("fromDate", true, true)
                            }
                          />
                          <span>
                            <MdDateRange />
                          </span>
                        </div>
                        {touched.fromDate && errors.fromDate && (
                          <span className="error-msg">{errors.fromDate}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col className="column">
                      <Form.Group className="form-group">
                        <Form.Label>Date To</Form.Label>
                        <div className="datepicker_box">
                          <CustomDatePicker
                            name="toDate"
                            minDate={values.fromDate}
                            selected={values.toDate}
                            onSelect={(date) => setFieldValue("toDate", date)}
                            dateFormat="MM/dd/yyyy" // Adjust the date format as needed
                            onBlur={() => setFieldTouched("toDate", true, true)}
                          />
                          <span>
                            <MdDateRange />
                          </span>
                        </div>
                        {touched.toDate && errors.toDate && (
                          <span className="error-msg">{errors.toDate}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Compliance days set to {""}
                          <input
                            type="number"
                            name="nofDays"
                            value={values.nofDays}
                            min={1}
                            onChange={handleChange}
                            onBlur={() =>
                              setFieldTouched("nofDays", true, true)
                            }
                            style={{ width: 30, textAlign: "center" }}
                            className="number-input-without-arrows"
                          />
                          {""} business days after entered data{" "}
                        </Form.Label>
                        <div className="checkboxHolder">
                          <Form.Check
                            type="checkbox"
                            className="include-check"
                            label="Include Complaints"
                            name="isCompliant"
                            checked={values.isCompliant}
                            onChange={handleChange}
                            onBlur={() =>
                              setFieldTouched("isCompliant", true, true)
                            }
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col
                      md={12}
                      className="d-flex justify-content-between mb-4"
                    >
                      <Button onClick={exportToExcel}>
                        <BiExport />
                        Export
                      </Button>
                      <Button style={{ minHeight: "44px" }} type="submit">
                        <BiSearch />
                        {loading ? "Loading..." : "Search"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
          <>
            {searchedData[0] && (
              <strong className="mb-4">
                Enstimated Compliance:{" "}
                <span style={{ backgroundColor: "#ec9706" }} className="px-1">
                  {renderPercentage}% Compliant
                </span>
              </strong>
            )}
            <SampleDataTable columns={columns} data={searchedData} />
          </>
          <Footer />
        </Card>
      </section>
    </>
  );
};

export default NonComplaintReport;
