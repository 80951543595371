// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Search_search__sW3Qb {
  display: flex;
  align-items: flex-start;
  position: relative;
}
.Search_search__sW3Qb input[type=text] {
  border-color: #e5e5e5;
  border-right: none;
  border-radius: 5px 0px 0 5px;
  min-width: 280px;
  font-size: 13px;
  padding: 10px;
}
.Search_search__sW3Qb input[type=text]::placeholder {
  font-size: 13px;
  color: #aaa;
}
.Search_search__sW3Qb input[type=text]:focus {
  border-color: #e5e5e5 !important;
}
.Search_search__sW3Qb .Search_searchBtn__LICYm {
  border-radius: 0px 5px 5px 0;
  background: #d9eaf5;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 44px;
  line-height: 38px;
  height: 43px;
}
.Search_search__sW3Qb .Search_searchBtn__LICYm i {
  font-size: 14px;
  color: #0073ba;
}
.Search_search__sW3Qb .Search_result__K00cB {
  position: absolute;
  top: 100%;
  background-color: #fff;
  width: 280px;
  padding: 12px;
  box-shadow: 1px 1px 1px 1px #ccc;
  list-style: none;
  border-radius: 0 0 5px 5px;
  z-index: 999;
  max-height: 200px;
  overflow: auto;
}
.Search_search__sW3Qb .Search_result__K00cB li:hover a {
  color: #0073ba;
}
.Search_search__sW3Qb .Search_result__K00cB li a {
  text-decoration: none;
  color: #000;
}
.Search_search__sW3Qb .Search_loader__rQlRQ {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
}`, "",{"version":3,"sources":["webpack://./src/Components/Search/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,kBAAA;AACF;AAAE;EACE,qBAAA;EACA,kBAAA;EACA,4BAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;AAEJ;AADI;EACE,eAAA;EACA,WAAA;AAGN;AADI;EACE,gCAAA;AAGN;AAAE;EACE,4BAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;AAEJ;AACE;EACE,eAAA;EACA,cAAA;AACJ;AAEE;EACE,kBAAA;EACA,SAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,gCAAA;EACA,gBAAA;EACA,0BAAA;EACA,YAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAGQ;EACE,cAAA;AADV;AAIM;EACE,qBAAA;EACA,WAAA;AAFR;AAME;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,2BAAA;AAJJ","sourcesContent":[".search {\n  display: flex;\n  align-items: flex-start;\n  position: relative;\n  input[type=\"text\"] {\n    border-color: #e5e5e5;\n    border-right: none;\n    border-radius: 5px 0px 0 5px;\n    min-width: 280px;\n    font-size: 13px;\n    padding: 10px;\n    &::placeholder {\n      font-size: 13px;\n      color: #aaa;\n    }\n    &:focus {\n      border-color: #e5e5e5 !important;\n    }\n  }\n  .searchBtn {\n    border-radius: 0px 5px 5px 0;\n    background: #d9eaf5;\n    color: #fff;\n    border: none;\n    cursor: pointer;\n    width: 44px;\n    line-height: 38px;\n    height: 43px;\n  }\n\n  .searchBtn i {\n    font-size: 14px;\n    color: #0073ba;\n  }\n\n  .result {\n    position: absolute;\n    top: 100%;\n    background-color: #fff;\n    width: 280px;\n    padding: 12px;\n    box-shadow: 1px 1px 1px 1px #ccc;\n    list-style: none;\n    border-radius: 0 0 5px 5px;\n    z-index: 999;\n    max-height: 200px;\n    overflow: auto;\n    li {\n      &:hover {\n        a {\n          color: #0073ba;\n        }\n      }\n      a {\n        text-decoration: none;\n        color: #000;\n      }\n    }\n  }\n  .loader {\n    position: absolute;\n    right: 50px;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": `Search_search__sW3Qb`,
	"searchBtn": `Search_searchBtn__LICYm`,
	"result": `Search_result__K00cB`,
	"loader": `Search_loader__rQlRQ`
};
export default ___CSS_LOADER_EXPORT___;
