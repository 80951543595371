import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const dummyData = {
    TicketNumber:"saddad",
    AssignedTo:"asdsada",
    Address:"dssadsad",

}

const SearchModal = (props) => {
  return (
    <Modal {...props} centered>
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Search Ticket
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="grid-example">
      <div className='custom-box'>
      <h6 style={{color:"#000",fontSize:"14px"}}>Ticket Number : <span style={{fontSize:"14px",color:"#a3a3a3"}}>Hello</span></h6>
      <h6 style={{color:"#000",fontSize:"14px"}}>Assigned To : <span style={{fontSize:"14px",color:"#a3a3a3"}}>Hello</span></h6>
      <h6 style={{color:"#000",fontSize:"14px"}}>Address : <span style={{fontSize:"14px",color:"#a3a3a3"}}>Hello</span></h6>
      <h6 style={{color:"#000",fontSize:"14px"}}>Nearest Intersection : <span style={{fontSize:"14px",color:"#a3a3a3"}}>Hello</span></h6>
      <h6 style={{color:"#000",fontSize:"14px"}}>Contractor : <span style={{fontSize:"14px",color:"#a3a3a3"}}>Hello</span></h6>
      <h6 style={{color:"#000",fontSize:"14px"}}>Region : <span style={{fontSize:"14px",color:"#a3a3a3"}}>Hello</span></h6>
      <h6 style={{color:"#000",fontSize:"14px"}}>Ticket Type : <span style={{fontSize:"14px",color:"#a3a3a3"}}>Hello</span></h6>
      <h6 style={{color:"#000",fontSize:"14px"}}>Complete/InComplete Text : <span style={{fontSize:"14px",color:"#a3a3a3"}}>Hello</span></h6>
      <h6 style={{color:"#000",fontSize:"14px"}}>City : <span style={{fontSize:"14px",color:"#a3a3a3"}}>Hello</span></h6>
      <h6 style={{color:"#000",fontSize:"14px"}}>Caller Name : <span style={{fontSize:"14px",color:"#a3a3a3"}}>Hello</span></h6>
      <h6 style={{color:"#000",fontSize:"14px"}}>Type Of Work : <span style={{fontSize:"14px",color:"#a3a3a3"}}>Hello</span></h6>
      <h6 style={{color:"#000",fontSize:"14px"}}>Station Codes : <span style={{fontSize:"14px",color:"#a3a3a3"}}>Hello</span></h6>
      <h6 style={{color:"#000",fontSize:"14px"}}>Date Type : <span style={{fontSize:"14px",color:"#a3a3a3"}}>Hello</span></h6>
      <h6 style={{color:"#000",fontSize:"14px"}}>Start Date : <span style={{fontSize:"14px",color:"#a3a3a3"}}>Hello</span></h6>
      <h6 style={{color:"#000",fontSize:"14px"}}>End Date : <span style={{fontSize:"14px",color:"#a3a3a3"}}>Hello</span></h6></div>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onHide}>Close</Button>
    </Modal.Footer>
  </Modal>
  )
}

export default SearchModal
