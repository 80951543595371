export const ticketType = [
    { value: "Routine", label: "Routine" },
    { value: "Homeowner", label: "Homeowner" },
    { value: "Priority", label: "Priority" },
  ];

  export const dateType = [
    { value: "0", label: "Select" },
    { value: "1", label: "Completed Date" },
    { value: "created_at", label: "Entered Date" },
    { value: "dueDate", label: "Due Date" },
    { value: "2", label: "Assigned Date" },
    { value: "3", label: "Cenceled Date" },
  ];