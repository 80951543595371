import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TablePagination,
} from "@mui/material";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

function DataTable({ columns, data, style, maxWidth, center, setSelectedIds }) {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => selected.indexOf(row.id) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((row) => row.id);
      setSelected(newSelected);
      setSelectedIds(newSelected);
    } else {
      setSelectedIds([]);
      setSelected([]);
    }
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, row.id];
    } else {
      newSelected = selected.filter((id) => id !== row.id);
    }

    setSelectedIds(newSelected);
    setSelected(newSelected);
  };

  const customPaginationControls = (
    <div className="custom-pagination-controls">
      <button
        className="upload-btn pagination-btn px-3"
        onClick={() => handleChangePage(null, page - 1)}
        disabled={page === 0}
      >
        <MdKeyboardArrowLeft />
      </button>
      {[...Array(Math.ceil(data.length / rowsPerPage)).keys()].map(
        (pageNumber) => (
          <button
            className={`pagination-counter ${
              page === pageNumber ? "active" : ""
            }`}
            key={pageNumber}
            onClick={() => handleChangePage(null, pageNumber)}
          >
            {pageNumber + 1}
          </button>
        )
      )}
      <button
        className="upload-btn pagination-btn"
        onClick={() => handleChangePage(null, page + 1)}
        disabled={page >= Math.ceil(data.length / rowsPerPage) - 1}
      >
        <MdKeyboardArrowRight />
      </button>
    </div>
  );

  return (
    <div className={style ? "" : "pb-5"}>
      <Paper>
        <TableContainer>
          <Table
            className={`${style && "checkboxTable"} ${
              maxWidth && "max-width"
            } ${center && "center"}`}
          >
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0 && selected.length < data.length
                    }
                    checked={selected.length === data.length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const isItemSelected = isSelected(row);
                  return (
                    <TableRow
                      key={row.id}
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} />
                      </TableCell>
                      {columns.map((column) => (
                        <TableCell key={column.id}>
                          {column.id === "oc" ? (
                            <Checkbox checked={row.oc} />
                          ) : (
                            row[column.id]
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {customPaginationControls}
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `Showing ${from + 1} to ${to} of ${count} entries`
          }
        />
      </Paper>
    </div>
  );
}

export default DataTable;
