import axios from "axios";

export async function addressToLatLng(address) {
    try {
        const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
            params: {
                address: address,
                key: "AIzaSyBqdKhAkPI_qCHco8_vVsnGrUGYjw7HL3M"
            }
        });

        if (response.data.status === 'OK') {
            const location = response.data.results[0].geometry.location;
            return location; // { lat: <latitude>, lng: <longitude> }
        } else {
            throw new Error('Geocoding failed: ' + response.data.status);
        }
    } catch (error) {
        console.error('Error occurred while geocoding:', error);
        throw error;
    }
}