// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logo_logo__o\\+CCE {
  position: relative;
  width: 200px;
  margin: auto;
  margin-bottom: 50px;
  visibility: visible;
  opacity: 1;
  transition: all ease 0.6s;
}
.Logo_logo__o\\+CCE a {
  display: block;
}
.Logo_logo__o\\+CCE img {
  display: block;
  width: 100%;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/Components/Logo/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACE,UAAA;EACA,yBAAA;AACJ;AACE;EACE,cAAA;AACJ;AAEE;EACE,cAAA;EACA,WAAA;EACA,YAAA;AAAJ","sourcesContent":[".logo{\n  position: relative;\n  width: 200px;\n  margin: auto;\n  margin-bottom: 50px;\n  visibility: visible;\n    opacity: 1;\n    transition: all ease 0.6s;\n\n  a{\n    display: block;\n  }\n\n  img{\n    display: block;\n    width: 100%;\n    height: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `Logo_logo__o+CCE`
};
export default ___CSS_LOADER_EXPORT___;
