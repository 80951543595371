// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#MainLayout_wrapper__oWuFj {
  position: relative;
  overflow: hidden;
  width: 100%;
}
#MainLayout_wrapper__oWuFj main {
  min-height: 100vh;
}

.MainLayout_content__pVTYV {
  margin-left: 260px;
  transition: all ease 0.6s;
}`, "",{"version":3,"sources":["webpack://./src/Layout/MainLayout/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,WAAA;AACF;AAAE;EACE,iBAAA;AAEJ;;AAGA;EACE,kBAAA;EACA,yBAAA;AAAF","sourcesContent":["#wrapper {\n  position: relative;\n  overflow: hidden;\n  width: 100%;\n  main{\n    min-height: 100vh;\n  }\n  \n}\n\n.content {\n  margin-left: 260px;\n  transition: all ease 0.6s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MainLayout_wrapper__oWuFj`,
	"content": `MainLayout_content__pVTYV`
};
export default ___CSS_LOADER_EXPORT___;
