import Heading from "Components/Heading";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { MdDateRange } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import Footer from "Components/Footer";

import CityAPIs from "../../APIs/city";
import SampleDataTable from "Components/DataTables";

import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import AutoComplete from "Components/AutoCompleteCity";

const City = () => {
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px",
    }),
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("City Name. is required"),
    country: Yup.string().required("Country Name. is required"),
  });

  const submitHandler = async (data, resetForm) => {
    console.log("DUCK", "submitHandler", JSON.stringify(data));
    setIsLoading(true);
    const res = await CityAPIs.createCity(data);
    if (res) {
      console.log("City Added Successfully === ", res.data);
      toast.success("City Added Successfully.");
      resetForm({ values: "" });
      getAllCities();
    }
    setIsLoading(false);
  };

  const getAllCities = async () => {
    const cities = await CityAPIs.getCities();

    if (cities) {
      console.log("DUCK", "getAllCities:cities", cities.data.data);
      setCities(cities.data.data);
    }
  };

  useEffect(() => {
    getAllCities();
  }, []);

  return (
    <>
      <section>
        <Heading text={"City"} />

        <Card>
          <Formik
            onSubmit={(values, { resetForm }) => {
              submitHandler(values, resetForm);
            }}
            initialValues={{
              name: "",
              country: "Canada",
            }}
            validationSchema={validationSchema}
            validateOnChange={false}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form className="dashboard-form mb-4" onSubmit={handleSubmit}>
                <Row className="align-items-center">
                  <Col className="column">
                    <AutoComplete
                      name={"name"}
                      field="name"
                      handleChange={handleChange}
                      values={values.name}
                      errors={errors.name}
                    />
                    {/* <Form.Group className="form-group">
                      <Form.Label>City Name</Form.Label>
                      <Form.Control
                        type="text"
                        name={"name"}
                        onChange={handleChange}
                        value={values.name}
                        required
                        isInvalid={!!errors.name}
                      />
                    </Form.Group> */}
                  </Col>
                  <Col className="column">
                    <Form.Group className="form-group">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        name={"country"}
                        value={values.country}
                        onChange={handleChange}
                        required
                        isInvalid={!!errors.country}
                      />
                    </Form.Group>
                  </Col>
                  <Col style={{ marginTop: "15px" }}>
                    <Button type={"submit"}>Add</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          <Row>
            <SampleDataTable
              columns={[
                { accessor: "name", Header: "City" },
                { accessor: "country", Header: "Country" },
              ]}
              data={cities}
            />
          </Row>
          <Footer />
        </Card>
      </section>
    </>
  );
};

export default City;
