// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Images/ticket.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#AuthLayout_wrapper__OT2Pt {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: 350px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/Layout/AuthLayout/index.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,kGAAA;EACA,2CAAA;EACA,uCAAA;EACA,sBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACJ","sourcesContent":["#wrapper {\n    position: relative;\n    overflow: hidden;\n    width: 100%;\n    background-image: url('../../Images/ticket.png'), url('../../Images/ticket.png');\n    background-position: left top, right bottom;\n    background-repeat: no-repeat, no-repeat;\n    background-size: 350px;\n    min-height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AuthLayout_wrapper__OT2Pt`
};
export default ___CSS_LOADER_EXPORT___;
