// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Images/loginBg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_fromHolder__6flTA {
  border-radius: 4px;
  background: #fff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: contain;
  min-width: 1200px;
  margin: auto;
  padding: 50px 100px;
}
.Login_fromHolder__6flTA .Login_logo__OIhNP {
  width: 250px;
  margin: auto;
  margin-bottom: 18px;
}
.Login_fromHolder__6flTA .Login_logo__OIhNP img {
  display: block;
  height: auto;
  width: 100%;
}
.Login_fromHolder__6flTA h3 {
  letter-spacing: 4px;
  font-weight: 500;
  font-size: 34px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Login/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,+CAAA;EACA,yDAAA;EACA,2CAAA;EACA,uCAAA;EACA,wBAAA;EACA,iBAAA;EACA,YAAA;EACA,mBAAA;AACF;AAAE;EACE,YAAA;EACA,YAAA;EACA,mBAAA;AAEJ;AADI;EACI,cAAA;EACA,YAAA;EACA,WAAA;AAGR;AAAE;EACE,mBAAA;EACA,gBAAA;EACA,eAAA;AAEJ","sourcesContent":[".fromHolder {\n  border-radius: 4px;\n  background: #fff;\n  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);\n  background-image: url(../../Images/loginBg.png);\n  background-position: left top, right bottom;\n  background-repeat: no-repeat, no-repeat;\n  background-size: contain;\n  min-width: 1200px;\n  margin: auto;\n  padding: 50px 100px;\n  .logo{\n    width: 250px;\n    margin: auto;\n    margin-bottom: 18px;\n    img{\n        display: block;\n        height: auto;\n        width: 100%;\n    }\n  }\n  h3{\n    letter-spacing: 4px;\n    font-weight: 500;\n    font-size: 34px;\n    \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fromHolder": `Login_fromHolder__6flTA`,
	"logo": `Login_logo__OIhNP`
};
export default ___CSS_LOADER_EXPORT___;
