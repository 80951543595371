import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row, Table } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { MdDateRange, MdOutlineRefresh } from "react-icons/md";
import Select from "react-select";
import { formatDate, formatTime } from "Helper/Converters";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik } from "formik";

import ReasonAPIs from "../../APIs/reason";
import TicketAPIs from "../../APIs/ticket";
import StationCodesAPIs from "../../APIs/station-code";
import TicketTypesAPIs from "../../APIs/ticket-types";
import AuthAPIs from "../../APIs/auth";
import FileAPIs from "../../APIs/file";

import { useSelector } from "react-redux";
import axios from "axios";
import { ENDPOINT } from "config/constants";
import { AiOutlineSave } from "react-icons/ai";
import { FiDelete } from "react-icons/fi";
import CustomDatePicker from "Components/CustomDatePicker";

const AccordionsSection = ({ ticketData }) => {
  console.log("DUCK", "AccordionsSection:ticketData", ticketData?.id);

  const [ticketLogs, setTicketLogs] = useState([]);
  const [ticketFiles, setTicketFiles] = useState([]);
  const [officeClearInfo, setOfficeClearInfo] = useState([]);
  const [preCompleteInfo, setPreCompleteInfo] = useState([]);
  const [users, setUsers] = useState([]);
  const [operationReasons, setOperationReasons] = useState([]);
  const [operationReason, setOperationReason] = useState("");
  const [operationComment, setOperationComment] = useState("");
  const [operationSpeakTo, setOperationSpeakTo] = useState("");
  const [operationEmail, setOperationEmail] = useState("");
  const [stationCodes, setStationCodes] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const user = useSelector((state) => state.auth);
  const userId = user.userId;

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px",
    }),
  };

  const [selectedUser, setSelectedUser] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const [rescheduleDate, setRescheduleDate] = useState(new Date());
  const [uploadFileLoader, setUploadFileLoader] = useState(false);
  const [deleteFileLoader, setDeleteFileLoader] = useState(false);

  const FileUploadComponent = () => {
    return (
      <div className="d-flex align-items-center justify-content-between w-100">
        <label htmlFor="fileInput" className="btn btn-primary">
          Choose a file
        </label>
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <Button
          style={{ minWidth: "140px" }}
          onClick={handleUpload}
          disabled={uploadFileLoader}
        >
          {uploadFileLoader ? "Loading..." : "Upload"}
        </Button>
      </div>
    );
  };

  const handleReassign = async () => {
    console.log("Reassigning to:", selectedUser);
    console.log("Reassigned Date:", endDate);

    if (!selectedUser) {
      toast.error("Please Select User to Assign");
      return;
    }
    if (!ticketData?.id) {
      toast.error("No Tickets Selected.");
      return;
    }
    console.log("Selected User id  ===", selectedUser);

    const res = await TicketAPIs.assignReassignTicket({
      ticketId: [ticketData?.id],
      userId: selectedUser,
      type: "re_assign",
      reAssignDate: endDate,
    });
    if (res) {
      toast.success("Ticket Assigned Successfully");
      getTicketLogs();
    }
  };

  const handleOperationLog = async () => {
    console.log("Reassigning to:", selectedUser);
    console.log("Reassigned Date:", endDate);

    if (!selectedUser) {
      toast.error("Please Select User ");
      return;
    }
    if (!operationReason && !operationComment) {
      toast.error("Add Reason and Comment");
      return;
    }

    const remarks =
      operationReason === "Re-Scheduled"
        ? `Ticket rescheduled for ${formatDate(
            rescheduleDate
          )} after speaking with ${operationSpeakTo} - ${operationComment}`
        : `${operationReason} - ${operationComment}`;

    const res = await TicketAPIs.addTicketLog({
      ticketIds: [ticketData?.id],
      userId: selectedUser,
      action: "OPERATION_LOG",
      remarks: remarks,
    });

    if (operationReason === "Re-Scheduled") {
      await TicketAPIs.updateTicket(ticketData?.id, {
        rescheduleDate: rescheduleDate,
      });
    }
    if (res) {
      toast.success("Operation Log Added");
      getTicketLogs();
      // setSelectedUser(null);
      setOperationComment("");
      // setOperationReason();
    }
  };

  const handleUpload = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    console.log("DUCk", "Accordians:selectedFile", selectedFile);
    if (!selectedFile) {
      alert("Please select a file");
      return;
    }
    setUploadFileLoader(true);
    const formData = new FormData();
    formData.append("files", selectedFile);
    var bearer_token = localStorage.getItem("accessToken");
    //headers["Authorization"] = `Bearer ${bearer_token}
    try {
      // Replace 'YOUR_UPLOAD_API_ENDPOINT' with the actual API endpoint for file upload
      const response = await axios.post(
        `${ENDPOINT.tickets.uploadFile}/${ticketData?.id}/upload-files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${bearer_token}`,
          },
        }
      );

      toast.success("File uploaded successfully");
      setSelectedFile(null);
      getTicketFiles();
    } catch (error) {
      toast.error(`File not uploaded: ${error.message}`);
    } finally {
      setUploadFileLoader(false);
    }
  };

  const deleteFile = async (id) => {
    setDeleteFileLoader(true);
    const res = await FileAPIs.deleteFile(id);
    if (res?.data?.success) {
      toast.success("File deleted successfully");
      getTicketFiles();
    }
    setDeleteFileLoader(false);
  };

  const getTicketLogs = async () => {
    if (ticketData?.id) {
      const tickets = await TicketAPIs.ticketLogs({
        ticketId: ticketData?.id,
      });

      if (tickets) {
        console.log("DUCK", "getTicketLogs:id", ticketData?.id);
        console.log("DUCK", "getTicketLogs:ticket", tickets.data.data);
        setTicketLogs(tickets.data.data);
      }
    }
  };

  const getTicketFiles = async () => {
    if (ticketData?.id) {
      const tickets = await TicketAPIs.ticketFiles({
        ticketId: [ticketData?.id],
      });

      if (tickets) {
        console.log("DUCK", "getTicketFiles:ticket", tickets.data.data);
        setTicketFiles(tickets.data.data);
      }
    }
  };

  const getOfficeClearData = async () => {
    if (ticketData?.id) {
      const tickets = await TicketAPIs.getOfficeClearStationCode(
        ticketData?.id
      );

      if (tickets) {
        console.log("DUCK", "getOfficeClearData:ticket", tickets.data.data);
        setOfficeClearInfo(tickets.data.data);
      }
    }
  };

  const getTicketPreCompleteData = async () => {
    if (ticketData?.id) {
      const tickets = await TicketAPIs.getTicketPreCompleteData(ticketData?.id);

      if (tickets) {
        console.log(
          "DUCK",
          "getTicketPreCompleteData:ticket",
          tickets.data.data
        );
        setPreCompleteInfo(tickets.data.data);
      }
    }
  };

  const getUsers = async () => {
    const users = await AuthAPIs.getAllUsers();
    const customArr = [{ value: "", label: "Select" }];
    if (users) {
      const arr = [...users.data.data];
      arr?.forEach((item) => {
        const data = { value: item?.id, label: item?.firstName };
        if (item?.id !== userId) customArr.push(data);
      });
      setUsers(customArr);
    }
  };

  const getAllReason = async () => {
    const types = await ReasonAPIs.getReason();

    const customArr = [];
    if (types) {
      const arr = [...types.data.data];
      arr?.forEach((item) => {
        const data = {
          value: item?.id,
          label: `${item?.name}`,
        };
        customArr.push(data);
      });
      setOperationReasons(customArr);
    }
  };

  const getAllStationCodes = async () => {
    const stationCodes = await StationCodesAPIs.getStationCode();

    const customArr = [];
    if (stationCodes) {
      const arr = [...stationCodes.data.data];
      arr?.forEach((item) => {
        const data = {
          value: item?.id,
          label: `${item?.name} `,
        };
        customArr.push(data);
      });
      setStationCodes(customArr.sort((a, b) => a.label.localeCompare(b.label)));
    }
  };

  const getAllTicketTypes = async () => {
    const types = await TicketTypesAPIs.getTicketType();

    const customArr = [];
    if (types) {
      const arr = [...types.data.data];
      arr?.forEach((item) => {
        const data = {
          value: item?.id,
          label: `${item?.name}`,
        };
        customArr.push(data);
      });
      setTicketTypes(customArr);
    }
  };

  useEffect(() => {
    getTicketLogs();
    getUsers();
    getTicketFiles();
    getAllReason();
    getAllStationCodes();
    getAllTicketTypes();
    getOfficeClearData();
    getTicketPreCompleteData();
  }, []);

  const validationSchema = Yup.object().shape({
    ticketType: Yup.string().required("Ticket Type. is required"),
    // additionalInfo: Yup.string().required("Additional Info is required"),
  });

  return (
    <Accordion defaultActiveKey="0" className="exclude-print">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Reassignment Log</Accordion.Header>
        <Accordion.Body>
          {/* <h6>Reassignment Logs</h6> */}
          {ticketLogs.length > 0 ? (
            ticketLogs
              .filter((obj) => obj.action === "TICKET_REASSIGN")
              .map((item) => {
                return (
                  <p>
                    {`Action: [${item?.action}] - Remarks: [${
                      item?.remarks
                    }] - Date: [${formatDate(
                      item?.createdAt
                    )}] - Time: [${formatTime(item?.createdAt)}]`}
                  </p>
                );
              })
          ) : (
            <p>No data available!</p>
          )}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Operations Log</Accordion.Header>
        <Accordion.Body>
          {ticketLogs.length > 0 ? (
            ticketLogs
              .filter((obj) => obj.action === "OPERATION_LOG")
              .map((item) => {
                return (
                  <p>
                    {`Action: [${item?.action}] - Remarks: [${
                      item?.remarks
                    }] - Date: [${formatDate(
                      item?.createdAt
                    )}] - Time: [${formatTime(item?.createdAt)}]`}
                  </p>
                );
              })
          ) : (
            <p>No data available!</p>
          )}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Reassign Tickets</Accordion.Header>
        <Accordion.Body>
          <Form>
            <Row>
              <div className="headingBox">
                <h6>Reassign Tickets</h6>
              </div>
              <Col className="column">
                <Form.Group className="form-group">
                  <Form.Label>Reassign To</Form.Label>
                  <Select
                    options={users}
                    styles={customStyles}
                    onChange={(selectedOption) => {
                      setSelectedUser(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col className="column">
                <Form.Group className="form-group">
                  <Form.Label>Reassigned Date</Form.Label>
                  <div className="datepicker_box">
                    <CustomDatePicker
                      selected={endDate}
                      onSelect={(date) => setEndDate(date)}
                      dateFormat="MM/dd/yyyy"
                    />
                    <span>
                      <MdDateRange />
                    </span>
                  </div>
                </Form.Group>
              </Col>

              <Col className="mb-4 column" style={{ maxWidth: "160px" }}>
                <Button
                  style={{ minHeight: "44px", marginTop: "30px" }}
                  onClick={handleReassign}
                >
                  <MdOutlineRefresh />
                  Reassign
                </Button>
              </Col>
            </Row>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Edit Ticket</Accordion.Header>
        <Accordion.Body>
          <Formik
            onSubmit={async (values) => {
              // submitHandler(values);

              let prevData = {
                contractorName: ticketData?.contractorName,
                primaryContact: ticketData?.primaryContact,
                email: ticketData?.email,
                dueDate: ticketData?.dueDate,
                dueTime: ticketData?.dueTime,
                locationLat: ticketData?.locationLat,
                locationLng: ticketData?.locationLng,
                nearestInteraction: ticketData?.nearestInteraction,
                city: ticketData?.city,
                typeOfWork: ticketData?.typeOfWork,
                workDoneFor: ticketData?.workDoneFor,
                workDepth: ticketData?.workDepth,
              };

              console.log(
                "DUCK",
                "submitHandler:editTicket: ticketData?.id",
                ticketData?.id
              );

              console.log(
                "DUCK",
                "submitHandler:editTicket: values",
                JSON.stringify(values)
              );

              console.log(
                "DUCK",
                "submitHandler:editTicket: prevData",
                JSON.stringify(prevData)
              );

              const mergedObject = { ...prevData, ...values };
              console.log(
                "DUCK",
                "submitHandler:editTicket",
                JSON.stringify(mergedObject)
              );

              const res = await TicketAPIs.updateTicket(
                ticketData?.id,
                mergedObject
              );
              if (res) {
                console.log("Ticket Updated Successfully === ", res.data);
                toast.success("Ticket Updated Successfully.");
              }

              console.log("DUCK", "submitHandler:editTicket:res", res);
            }}
            initialValues={{
              ticketType: "",
              additionalInfo: ticketData?.additionalInfo,
              region: "",
              stationCodeIds: [],
            }}
            validationSchema={validationSchema}
            validateOnChange={false}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
            }) => (
              <Form
                className="dashboard-form mb-4"
                noValidate
                onSubmit={handleSubmit}
              >
                <Form.Group className="form-group">
                  <Form.Label>Locatebase Region</Form.Label>
                  <Form.Control
                    type="text"
                    name={"region"}
                    onChange={handleChange}
                    value={values.region}
                    required
                    isInvalid={!!errors.region}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Station Code</Form.Label>
                  <Row>
                    <Col xs={9} md={10}>
                      <Select
                        isMulti
                        name={"stationCodeIds"}
                        options={stationCodes}
                        styles={customStyles}
                        onChange={(selectedOptions) => {
                          console.log(
                            "DUCK",
                            "selectedOptions",
                            selectedOptions
                          );
                          setFieldValue(
                            "stationCodeIds",
                            selectedOptions.map((option) => option.value)
                          );
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Ticket Type</Form.Label>
                  <Select
                    name={"ticketType"}
                    options={ticketTypes}
                    styles={customStyles}
                    onChange={(selectedOptions) => {
                      console.log("DUCK", "ticketType", selectedOptions.label);
                      setFieldValue("ticketType", selectedOptions.label);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {/* {errors.ticketType} */}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Add Info</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Additional Info..."
                    as={"textarea"}
                    rows={3}
                    name={"additionalInfo"}
                    onChange={handleChange}
                    value={values.additionalInfo}
                    required
                    isInvalid={!!errors.additionalInfo}
                  />
                </Form.Group>
                <Button type={"submit"}>
                  <AiOutlineSave />
                  Save
                </Button>
              </Form>
            )}
          </Formik>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Add Operations Log</Accordion.Header>
        <Accordion.Body>
          <Form>
            <div className="d-flex align-items-center mb-3">
              <p className="me-3"> Total Number:</p>
              <span style={{ color: "#757575" }}>{ticketData?.ticketNo}</span>
            </div>
            <Form.Group className="form-group">
              <Form.Label>Specific User</Form.Label>
              <Select
                options={users}
                styles={customStyles}
                onChange={(selectedOption) => {
                  setSelectedUser(selectedOption.value);
                }}
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label>Specific Reason</Form.Label>
              <Select
                options={operationReasons}
                styles={customStyles}
                onChange={(selectedOptions) => {
                  console.log("DUCK", "operationReason", selectedOptions.label);
                  setOperationReason(selectedOptions.label);
                }}
              />
            </Form.Group>

            {operationReason === "Re-Scheduled" && (
              <Row>
                <Col className="column" md={3}>
                  <Form.Group className="form-group mb-0">
                    <Form.Label>Reschedule Date</Form.Label>
                    <div className="datepicker_box">
                      <CustomDatePicker
                        selected={rescheduleDate}
                        onSelect={(date) => setRescheduleDate(date)}
                        dateFormat="MM/dd/yyyy"
                      />
                      <span>
                        <MdDateRange />
                      </span>
                    </div>
                  </Form.Group>
                </Col>
                <Col className="column" md={3}>
                  <Form.Group className="form-group">
                    <Form.Label>Who did you speak with ?</Form.Label>
                    <Form.Control
                      type="text"
                      value={operationSpeakTo}
                      onChange={(evt) => {
                        setOperationSpeakTo(evt.currentTarget.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col className="column" md={3}>
                  <Form.Group className="form-group">
                    <Form.Label> Email a re-schedule notice to</Form.Label>
                    <Form.Control
                      type="text"
                      value={operationEmail}
                      onChange={(evt) => {
                        setOperationEmail(evt.currentTarget.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Form.Group className="form-group">
              <Form.Label>Comments</Form.Label>
              <Form.Control
                type="number"
                as={"textarea"}
                rows={3}
                value={operationComment}
                onChange={(evt) => {
                  setOperationComment(evt.currentTarget.value);
                }}
              />
            </Form.Group>
            {operationReason === "Re-Scheduled" && <FileUploadComponent />}

            <Col className="mb-4 column" style={{ maxWidth: "160px" }}>
              <Button
                style={{ minHeight: "44px", marginTop: "30px" }}
                onClick={handleOperationLog}
              >
                Add Log
              </Button>
            </Col>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>Office Clear Info</Accordion.Header>
        <Accordion.Body>
          {officeClearInfo.length > 0 ? (
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Station Code</th>
                  <th>User</th>
                  <th>Is Office Cleared</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {officeClearInfo.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {
                        item.stationCode
                          ?.name /* Assuming this is the correct property */
                      }
                    </td>
                    <td>
                      {
                        item.user
                          ?.firstName /* Assuming you have a user property */
                      }
                    </td>
                    <td>{item.isOfficeCleared ? "Yes" : "No"}</td>
                    <td>{`${formatDate(item.createdAt)} - ${formatTime(
                      item.createdAt
                    )}`}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No data available!</p>
          )}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header>Pre-Complete Info</Accordion.Header>
        <Accordion.Body>
          {preCompleteInfo.length > 0 ? (
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Field Marking</th>
                  <th>Locate Void</th>
                  <th>Caution Message</th>
                  <th>Locator Comments</th>
                  <th>Gas Unit</th>
                  <th>Hydro Unit</th>
                  <th>Telecom Unit</th>
                  <th>CATV Unit</th>
                  <th>Water Unit</th>
                  <th>Sewer Unit</th>
                  <th>Other Unit</th>
                  <th>Street Light Unit</th>
                  <th>Traffic Light Unit</th>
                  <th>Zum Unit</th>
                  <th>Sanitary Unit</th>
                  <th>Brampton Storm Unit</th>
                  <th>Peel Storm Unit</th>
                  <th>Electrical Unit</th>
                  <th>User</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {preCompleteInfo.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.fieldMarking}</td>
                    <td>{item?.locateVoid}</td>
                    <td>{item?.cautionMessage}</td>
                    <td>{item?.locatorComments}</td>
                    <td>{isNaN(item?.gasUnit) ? 0 : item?.gasUnit}</td>
                    <td>{isNaN(item?.hydroUnit) ? 0 : item?.hydroUnit}</td>
                    <td>{isNaN(item?.telecomUnit) ? 0 : item?.telecomUnit}</td>
                    <td>{isNaN(item?.catvUnit) ? 0 : item?.catvUnit}</td>
                    <td>{isNaN(item?.waterUnit) ? 0 : item?.waterUnit}</td>
                    <td>{isNaN(item?.sewerUnit) ? 0 : item?.sewerUnit}</td>
                    <td>{isNaN(item?.otherUnit) ? 0 : item?.otherUnit}</td>
                    <td>
                      {isNaN(item?.streetLightUnit) ? 0 : item?.streetLightUnit}
                    </td>
                    <td>
                      {isNaN(item?.trafficLightUnit)
                        ? 0
                        : item?.trafficLightUnit}
                    </td>
                    <td>{isNaN(item?.zumUnit) ? 0 : item?.zumUnit}</td>
                    <td>
                      {isNaN(item?.sanitaryUnit) ? 0 : item?.sanitaryUnit}
                    </td>
                    <td>
                      {isNaN(item?.bramptonStormUnit)
                        ? 0
                        : item?.bramptonStormUnit}
                    </td>
                    <td>
                      {isNaN(item?.peelStormUnit) ? 0 : item?.peelStormUnit}
                    </td>
                    <td>
                      {isNaN(item?.electricalUnit) ? 0 : item?.electricalUnit}
                    </td>
                    <td>
                      {
                        item.user
                          ?.firstName /* Assuming you have a user property */
                      }
                    </td>
                    <td>{`${formatDate(item.finishedDate)} - ${formatTime(
                      item.finishedDate
                    )}`}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No data available!</p>
          )}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Add Files</Accordion.Header>
        <Accordion.Body>
          <Form>
            <Col className="mb-4 column">
              <FileUploadComponent />
            </Col>
          </Form>
          {ticketFiles.length > 0 ? (
            ticketFiles.map((item) => {
              return (
                <div>
                  <a
                    href={`${item?.presignedUrl}`}
                    onClick={async () => {
                      // alert(JSON.stringify(item,null,2))
                      const response = await TicketAPIs.addLog({
                        ticketIds: [ticketData?.id],
                        userId,
                        action: "TICKET_IMAGE_DOWNLOADED",
                        remarks: `IMAGE : /${item.name} has been downloaded Successfully! by ${user.user?.firstName}`,
                      });
                      console.log("resss", response);
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item?.name}
                  </a>
                  <FiDelete
                    size={17}
                    onClick={() => deleteFile(item?.id)}
                    className="ms-2"
                    style={{ color: "red", cursor: "pointer" }}
                  />
                </div>
              );
            })
          ) : (
            <p>No data available!</p>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionsSection;
