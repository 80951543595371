// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DataTables_search__r5Z8I {
  display: flex;
  align-items: flex-start;
}
.DataTables_search__r5Z8I input[type=text] {
  border-color: #E5E5E5;
  border-right: none;
  border-radius: 5px 0px 0 5px;
  min-width: 280px;
  border-bottom: 0;
  border-bottom: 0;
  font-size: 13px;
  padding: 10px;
}
.DataTables_search__r5Z8I input[type=text]::placeholder {
  font-size: 13px;
  color: #AAA;
}
.DataTables_search__r5Z8I input[type=text]:focus {
  border-color: #E5E5E5 !important;
}
.DataTables_search__r5Z8I .DataTables_searchBtn__skt8F {
  border-radius: 0px 5px 5px 0;
  background: #D9EAF5;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 44px;
  line-height: 38px;
  height: 43px;
}
.DataTables_search__r5Z8I .DataTables_searchBtn__skt8F i {
  font-size: 14px;
  color: #0073BA;
}`, "",{"version":3,"sources":["webpack://./src/Components/DataTables/index.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;AACJ;AACI;EACI,qBAAA;EACA,kBAAA;EACA,4BAAA;EACA,gBAAA;EACC,gBAAA;EACA,gBAAA;EACD,eAAA;EACA,aAAA;AACR;AAAQ;EACI,eAAA;EACA,WAAA;AAEZ;AAAQ;EACE,gCAAA;AAEV;AACM;EACE,4BAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACD,WAAA;EACA,iBAAA;EACA,YAAA;AACP;AAEM;EACE,eAAA;EACA,cAAA;AAAR","sourcesContent":[".search{\n    display: flex;\n    align-items: flex-start;\n    \n    input[type=\"text\"] {\n        border-color:  #E5E5E5;\n        border-right: none;\n        border-radius: 5px 0px 0 5px;\n        min-width: 280px;\n         border-bottom: 0;\n         border-bottom: 0;\n        font-size: 13px;\n        padding: 10px;\n        &::placeholder{\n            font-size: 13px;\n            color: #AAA;\n        }\n        &:focus{\n          border-color: #E5E5E5 !important;\n        }\n      }\n      .searchBtn {\n        border-radius: 0px 5px 5px 0;\n        background: #D9EAF5;\n        color: #fff;\n        border: none;\n        cursor: pointer;\n       width: 44px;\n       line-height: 38px;\n       height: 43px;\n      }\n      \n      .searchBtn i {\n        font-size: 14px;\n        color: #0073BA;\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": `DataTables_search__r5Z8I`,
	"searchBtn": `DataTables_searchBtn__skt8F`
};
export default ___CSS_LOADER_EXPORT___;
