// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TicketDetails_flexBox__qsqMP {
  display: flex;
  margin-bottom: 8px;
}
.TicketDetails_flexBox__qsqMP span {
  color: #757575;
  font-weight: 300;
}
.TicketDetails_flexBox__qsqMP p {
  color: #313131;
  margin-bottom: 0;
  font-weight: 600;
  margin-right: 12px;
  min-width: 135px;
}
@media screen and (max-width: 580px) {
  .TicketDetails_flexBox__qsqMP p {
    min-width: 85px;
  }
}

.TicketDetails_heading__75zyD {
  position: relative;
}
.TicketDetails_heading__75zyD:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 100%;
  border-bottom: 1px solid #e5e5e5;
}`, "",{"version":3,"sources":["webpack://./src/Pages/TicketDetails/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;AACF;AAAE;EACE,cAAA;EACA,gBAAA;AAEJ;AAAE;EACE,cAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AAEJ;AADI;EANF;IAOM,eAAA;EAIN;AACF;;AAAA;EACE,kBAAA;AAGF;AAFE;EACE,kBAAA;EACA,WAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AAIJ","sourcesContent":[".flexBox {\n  display: flex;\n  margin-bottom: 8px;\n  span {\n    color: #757575;\n    font-weight: 300;\n  }\n  p {\n    color: #313131;\n    margin-bottom: 0;\n    font-weight: 600;\n    margin-right: 12px;\n    min-width:135px ;\n    @media screen and (max-width: 580px) {\n        min-width: 85px;\n    }\n  }\n}\n\n.heading {\n  position: relative;\n  &:after {\n    position: absolute;\n    content: \"\";\n    left: 0;\n    right: 0;\n    top: 100%;\n    border-bottom: 1px solid #e5e5e5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexBox": `TicketDetails_flexBox__qsqMP`,
	"heading": `TicketDetails_heading__75zyD`
};
export default ___CSS_LOADER_EXPORT___;
